import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  Background,
  Controls,
  useReactFlow,
  ReactFlowProvider,
  // useViewport,
} from "react-flow-renderer";
import { CartesianGrid } from "recharts";
import { data } from "./dummy";

const SummaryTree = ({ dataSource, mode }) => {
  console.log(dataSource);
  const [nodes, _, onNodesChange] = useNodesState(dataSource.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(dataSource.edges);
  const [nodeData, setNodeData] = useState({});
  const [edgesData, setEdgeData] = useState({});
  const [conditions, setConditions] = useState([]);
  const onConnect = useCallback(
    (params) => setEdges((els) => addEdge(params, els)),
    []
  );

  const changeNodeStyle = (elements) => {
    console.log(elements, " Found Elements ");
  };

  useEffect(() => {
    console.log(dataSource);
    setNodeData(dataSource.nodes);
    setEdgeData(dataSource.edges);
    let condition = [];
    dataSource.nodes.map((x) => {
      console.log(x);
      if (x.type === "condition" || x.type === "input") {
        condition.push(x.data.label);
      }
    });
    setConditions(condition);
  }, [dataSource]);

  useEffect(() => {
    let some = document.getElementsByClassName("react-flow__node");
    let elements = document.querySelectorAll("div.react-flow__node");
    console.log(some.length, elements);
    for (let i = 0; i < elements.length; i++) {
      console.log(elements[i].textContent);
      console.log(conditions);
      if (conditions.includes(elements[i].textContent)) {
        console.log(elements[i].textContent);
        elements[i].style.borderRadius = "60px";
        elements[i].style.height = "110px";
        elements[i].style.width = "110px";
        elements[i].style.display = "flex";
        elements[i].style.alignItems = "center";
        elements[i].style.justifyContent = "center";
        elements[i].style.textAlign = "center";
        elements[i].style.fontWeight = "500";
        elements[i].style.textTransform = "uppercase";
        elements[i].style.backgroundColor = "rgba(235, 204,44,0.3)";
        elements[i].style.border = "solid 2px #EDC252";
        elements[i].classList.remove("invert_node");
      } else {
        elements[i].classList.add("invert_node");
        elements[i].style.pointerEvents = "none";
      }
    }
    console.log("some....", [...some], some.length);
  }, [nodeData, edgesData]);

  // useEffect(() => {
  //   zoomOut({ duration: 800 });
  // }, []);

  return (
    <div>
      {" "}
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          fitView
          attributionPosition="bottom-left"
          style={mode === "preview" ? { height: "600px" } : { height: "400px" }}
          panOnDrag={true}
        >
          <Controls showInteractive={false} />
        </ReactFlow>
      </ReactFlowProvider>
    </div>
  );
};

export default SummaryTree;
