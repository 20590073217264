import React from "react";
import "./card.css";
import {
  DotChartOutlined,
  RiseOutlined,
  FallOutlined,
} from "@ant-design/icons";
import { ReactComponent as Average } from "../../assests/medianWhite.svg";
import { ReactComponent as Median } from "../../assests/medianWhite.svg";
import { ReactComponent as Max } from "../../assests/max.svg";
import { ReactComponent as Min } from "../../assests/min.svg";
import { ReactComponent as Sum } from "../../assests/sumWhite.svg";
import matchers from "@testing-library/jest-dom/matchers";

const fieldcards = ({ figures, operation, diff, theme }) => {
  console.log(typeof diff);

  const getOperationIcon = (type) => {
    switch (type) {
      case "sum":
        return <Sum fill="white" />;
      case "min":
        return <Min stroke="white" />;
      case "max":
        return <Max stroke="white" />;
      case "median":
        return <Median fill="white" />;
      case "average":
        return <Average fill="white" />;
      default:
      // code block
    }
  };

  function numFormatter(num) {
    console.log(num);
    if (num < 999) {
      return Math.round(num * 100) / 100;
    }
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  }

  return (
    <div className="field_card_container" style={{ paddingLeft: "15px" }}>
      <div className="field_card_header">
        <span className="field_icon" style={{ backgroundColor: theme }}>
          {getOperationIcon(operation.toLowerCase())}
        </span>
        <span
          style={{
            backgroundColor: "#EBEBEB",
            padding: "4px",
            height: "20px",
            borderRadius: "5px",
            fontWeight: "bold",
            position: "relative",
            left: "28px",
          }}
        >
          <p style={{ position: "relative", top: "-8px" }}>...</p>
        </span>
      </div>
      <span
        style={{
          position: "relative",
          // left: "15px",
          top: "7px",
          color: "#606060",
        }}
      >
        {operation}
      </span>
      <h2 style={{ paddingTop: "10px" }}>{figures}</h2>
      {diff !== "None" ? (
        diff > 0 ? (
          <>
            <span
              style={{
                color: diff > 0 ? "#33C8A5" : "#FF2626",
                fontSize: "11px",
                fontWeight: "normal",
                marginLeft: "6px",
                fontWeight: "500",
                position: "relative",
                left: "-4px",
                top: "-7px",
                // paddingLeft: "15px",
              }}
            >
              {diff > 0 ? <RiseOutlined /> : <FallOutlined />}
              {Math.abs(numFormatter(Math.abs(diff)))}%
            </span>
            <span
              style={{
                color: "#606060",
                fontSize: "11px",
                position: "relative",
                left: "-4px",
                top: "-7px",
              }}
            >
              {" "}
              from perious period
            </span>
          </>
        ) : (
          <>
            <span
              style={{
                color: "red",
                fontSize: "11px",
                fontWeight: "normal",
                marginLeft: "6px",
                fontWeight: "500",
                position: "relative",
                left: "-4px",
                top: "-7px",
              }}
            >
              <FallOutlined />
              {numFormatter(Math.abs(diff))}%
            </span>
            <span
              style={{
                color: "#606060",
                fontSize: "11px",
                position: "relative",
                left: "-4px",
                top: "-7px",
              }}
            >
              {" "}
              from perious period
            </span>
          </>
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default fieldcards;
