const dataSource = [];

for (let i = 1; i < 100; i++) {
  dataSource.push({
    key: i,
    storeId: `NY00${i}`,
    date: `${i} May 2022`,
    status: "Open",
    sales: Math.floor(i * 100 * Math.random(i)),
    balance: "balance",
    deposit: Math.floor(i * 100 * Math.random(i)),
  });
}

const dataSourc = [
  {
    key: "1",
    storeId: "NY_00016",
    date: "15 May 2022",
    status: "Open",
    sales: 12,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "2",
    storeId: "NY_000178",
    date: "14 May 2022",
    status: "Open",
    sales: 12,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "3",
    storeId: "NY_00890",
    date: "17 May 2022",
    status: "Open",
    sales: 14,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "4",
    storeId: "NY_0090",
    date: "18 May 2022",
    status: "Open",
    sales: 44,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "5",
    storeId: "NY_0089",
    date: "19 May 2022",
    status: "Open",
    sales: 23,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "6",
    storeId: "NY_0090",
    date: "25 May 2022",
    status: "Open",
    sales: 45,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "7",
    storeId: "NY_0006",
    date: "15 May 2022",
    status: "Open",
    sales: 90,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "8",
    storeId: "NY_0005",
    date: "19 May 2022",
    status: "Open",
    sales: 89,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "9",
    storeId: "NY_0003",
    date: "13 May 2022",
    status: "Open",
    sales: 34,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "10",
    storeId: "NY_0002",
    date: "16 May 2022",
    status: "Open",
    sales: 9,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "11",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "12",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "13",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "14",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "15",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "16",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "17",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "18",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "19",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "29",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "18",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "99",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "1",
    storeId: "NY_00016",
    date: "15 May 2022",
    status: "Open",
    sales: 12,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "2",
    storeId: "NY_000178",
    date: "14 May 2022",
    status: "Open",
    sales: 12,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "3",
    storeId: "NY_00890",
    date: "17 May 2022",
    status: "Open",
    sales: 14,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "4",
    storeId: "NY_0090",
    date: "18 May 2022",
    status: "Open",
    sales: 44,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "5",
    storeId: "NY_0089",
    date: "19 May 2022",
    status: "Open",
    sales: 23,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "6",
    storeId: "NY_0090",
    date: "25 May 2022",
    status: "Open",
    sales: 45,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "7",
    storeId: "NY_0006",
    date: "15 May 2022",
    status: "Open",
    sales: 90,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "8",
    storeId: "NY_0005",
    date: "19 May 2022",
    status: "Open",
    sales: 89,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "9",
    storeId: "NY_0003",
    date: "13 May 2022",
    status: "Open",
    sales: 34,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "10",
    storeId: "NY_0002",
    date: "16 May 2022",
    status: "Open",
    sales: 9,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "11",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "12",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "13",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "14",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "15",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "16",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "17",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "18",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "19",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "29",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "18",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "99",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "1",
    storeId: "NY_00016",
    date: "15 May 2022",
    status: "Open",
    sales: 12,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "2",
    storeId: "NY_000178",
    date: "14 May 2022",
    status: "Open",
    sales: 12,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "3",
    storeId: "NY_00890",
    date: "17 May 2022",
    status: "Open",
    sales: 14,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "4",
    storeId: "NY_0090",
    date: "18 May 2022",
    status: "Open",
    sales: 44,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "5",
    storeId: "NY_0089",
    date: "19 May 2022",
    status: "Open",
    sales: 23,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "6",
    storeId: "NY_0090",
    date: "25 May 2022",
    status: "Open",
    sales: 45,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "7",
    storeId: "NY_0006",
    date: "15 May 2022",
    status: "Open",
    sales: 90,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "8",
    storeId: "NY_0005",
    date: "19 May 2022",
    status: "Open",
    sales: 89,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "9",
    storeId: "NY_0003",
    date: "13 May 2022",
    status: "Open",
    sales: 34,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "10",
    storeId: "NY_0002",
    date: "16 May 2022",
    status: "Open",
    sales: 9,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "11",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "12",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "13",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "14",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "15",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "16",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "17",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "18",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "19",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "29",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "18",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
  {
    key: "99",
    storeId: "NY_0001",
    date: "18 May 2022",
    status: "Open",
    sales: 78,
    balance: "balance",
    deposit: 23,
  },
];
export default dataSource;
