import React, { useEffect, useState, useRef } from "react";
import {
  Select,
  Radio,
  Divider,
  Button,
  Input,
  Form,
  Tag,
  message,
} from "antd";
import "./what.css";
import Selected from "./selected";
import "../../components/buttons/buttons.css";
import axios from "axios";

import { customFilterResponse } from "./whatResponses";
const { Option } = Select;

const API_URL = process.env.REACT_APP_API_URL;

const tagRender = (props) => {
  const { label, closable, onClose } = props;

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color={"rgba(111,82,237,80%)"}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        marginBottom: "2px",
        marginTop: "2px",
      }}
    >
      {label}
    </Tag>
  );
};

const customFilter = ({
  closeFilter,
  customOptions,
  parentCallback,
  postData,
  updatePayload,
  setLoaders,
}) => {
  const [dropdown, setDropdown] = useState([]);
  const [payload, setPayload] = useState({});
  const [filterChosen, setFilterChosen] = useState([]);
  const [catergory, setCategory] = useState("categorical");
  const [equation, setEquation] = useState([{ label: "=", value: "equals" }]);
  const [selections, setSelect] = useState([]);
  const [selectedRecord, setSelectedRecorded] = useState({});
  const [subSelection, setSubSelection] = useState([]);
  const [equationSelection, setEquationSelection] = useState("none");
  const [numericValue, setNumericValue] = useState("none");
  const categoryEquation = [{ label: "=", value: "equals" }];
  const [Validated, setValidated] = useState(false);
  const numericEquation = [
    { label: "=", value: "equals" },
    // { label: ">", value: "greater" },
    // { label: "<", value: "lesser" },
    { label: ">=", value: "greaterthanequal" },
    { label: "<=", value: "lesserthanqual" },
  ];
  const customSelections = JSON.stringify(localStorage.getItem("customFilter"));
  const formRef = useRef();
  console.log(postData);

  // Handling function
  const handleFilterSelection = (value, record) => {
    console.log(value, record);
    setSelectedRecorded(record);
    switch (record.type) {
      case "numeric":
        // code block
        setCategory(record.type);
        setEquation(numericEquation);
        break;
      case "categorical":
        setCategory(record.type);
        setEquation(categoryEquation);
        const optionsAvailable = record.subSelection.map((ele) => {
          return { label: ele.toUpperCase(), value: ele };
        });
        setSelect(optionsAvailable);
        // code block
        break;
      default:
      // code block
    }
  };

  // Hnadling and operator
  const handleAndOperator = (e) => {
    console.log(e);
    console.log(subSelection);
    if (subSelection.length !== 0) {
      let chosen = [...filterChosen];
      let record = {
        ...selectedRecord,
        sub: subSelection,
        equ: equationSelection,
        num: numericValue,
      };
      console.log(chosen);
      chosen.push(record);
      console.log(chosen);
      setFilterChosen(chosen);
      setSelectedRecorded({});
      formRef.current.resetFields();
    }
  };

  // Deletion
  const handleDelete = (record) => {
    console.log(record);
    let arr2 = [record];
    let difference = filterChosen.filter((x) => !arr2.includes(x));
    console.log(difference);
    setFilterChosen(difference);
  };

  // Setting up dropdown options
  useEffect(() => {
    let tempOptions = [];
    console.log(customOptions);
    if (customOptions.length !== 0) {
      customOptions.map((obj) => {
        let tempObj = {
          label: obj.label.toUpperCase(),
          value: obj.name,
          type: obj.type,
          subSelection: obj.value_options || "none",
        };
        tempOptions.push(tempObj);
      });
      console.log("Temporary Options:", tempOptions);
    }
    setDropdown(tempOptions);
  }, []);

  // Setting up the filter category and equations

  const optionsWith = [
    {
      label: "AND",
      value: "AND",
    },
  ];

  const getFilterPayload = (filterChosen) => {
    let FilteredArray = [];
    if (filterChosen.length !== 0) {
      FilteredArray = filterChosen.map((ele) => {
        return ele.type === "categorical"
          ? {
              column: ele.value,
              filter_type: "categorical",
              values: ele.sub,
            }
          : {
              column: ele.value,
              filter_type: "numeric",
              upper_limit:
                ele.equ === "lesserthanqual" || ele.equ === "equals"
                  ? ele.num
                  : null,
              lower_limit:
                ele.equ === "greaterthanequal" || ele.equ === "equals"
                  ? ele.num
                  : null,
            };
      });
    }
    let newEntry = formRef.current.get;
    return FilteredArray;
  };
  // Side Effects

  const postEventFilter = async () => {
    let dataMet = JSON.parse(localStorage.getItem("metricFilter"));
    let dataEvent = JSON.parse(localStorage.getItem("eventFilter"));
    let pass = true;
    let add = { ...dataMet, ...dataEvent };
    console.log(filterChosen, add);
    let chosen = [...filterChosen];
    if (Object.keys(selectedRecord).length !== 0) {
      console.log(selectedRecord);
      console.log(subSelection);
      let record = {
        ...selectedRecord,
        sub: subSelection,
        equ: equationSelection,
        num: numericValue,
      };
      console.log(chosen);
      chosen.push(record);
      console.log(chosen);
      if (record.type === "numeric") {
        console.log(record, "Validations");
        if (
          (record.upper_limit === null && record.lower_limit === null) ||
          record.value === ""
        ) {
          pass = false;
        } else {
          pass = true;
        }
      } else {
        console.log(record, "Check Validations  ");
        if (record.sub.length === 0 || record.value.length === "") {
          console.log("Failed");
          pass = false;
        } else {
          pass = true;
        }
      }
    }

    let postPayload = {
      ...add,
      filter: getFilterPayload(chosen),
    };

    console.log(postPayload, Validated);
    if (pass) {
      setLoaders(true);

      try {
        let custom_config = {
          method: "post",
          url: `${API_URL}/events_mgmt/event_temp_view`,
          headers: {
            key: "Y6KBUDa97drMc6r5oDlG4aHSoVYulRJb",
            "Content-Type": "application/json",
          },
          data: JSON.stringify(postPayload),
        };

        setPayload(postPayload);
        updatePayload(postPayload);
        axios(custom_config)
          .then(function(response) {
            console.log(response.data);
            message.success("Successfully fetched the posted custom filter");
            setLoaders(false);
            parentCallback(response.data);
            closeFilter();
            localStorage.setItem("customFilter", JSON.stringify(chosen));
          })
          .catch(function(error) {
            console.log(error);
          });
      } catch (e) {
        message.error("Failed to fetch the metrics list");
        console.log("err...", e);
      }
    } else {
      message.error("Please fill the blank fields");
    }
  };

  useEffect(() => {
    console.log(subSelection);
  }, [subSelection]);

  useEffect(() => {
    console.log(
      "Custom Filter Applied",
      JSON.parse(JSON.parse(customSelections))
    );
    setFilterChosen(JSON.parse(JSON.parse(customSelections)));
  }, [customSelections]);

  useEffect(() => {
    console.log(Validated);
  }, [Validated]);

  return (
    <div className="customFilter">
      <div
        style={{
          position: "relative",
          // width: "590px",
          borderRadius: "5px",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          //   border: "solid 2px black",
          backgroundColor: "white",
        }}
      >
        <div>
          <div className="custom">
            {filterChosen !== null
              ? filterChosen.length !== 0 &&
                filterChosen.map((obj) => (
                  <Selected chosen={obj} parentCallback={handleDelete} />
                ))
              : null}
            <Form name="basic" style={{ display: "flex" }} ref={formRef}>
              <span className="custom_select">
                <Form.Item
                  name="entity"
                  rules={[
                    {
                      required: true,
                      message: "Please select an entity!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    options={dropdown}
                    style={{
                      width: 200,
                    }}
                    placeholder="Search to Select"
                    onChange={(value, record) => {
                      handleFilterSelection(value, record);
                    }}
                  ></Select>
                </Form.Item>
              </span>
              <span className="custom_select">
                <Form.Item
                  name="equation"
                  rules={[
                    {
                      required: true,
                      message: "Please select equation!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{
                      width: 60,
                    }}
                    options={equation}
                    onSelect={(value) => setEquationSelection(value)}
                  ></Select>
                </Form.Item>
              </span>
              <span className="custom_select">
                {catergory === "categorical" ? (
                  <Form.Item
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: "Please select category!",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      showSearch
                      tagRender={tagRender}
                      style={{
                        width: 150,
                      }}
                      options={selections}
                      onChange={(value, record) => setSubSelection(value)}
                    ></Select>
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="numeric"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a value!",
                      },
                    ]}
                  >
                    <Input
                      style={{ width: "150px" }}
                      onChange={(e) => setNumericValue(e.target.value)}
                      type="number"
                    />
                  </Form.Item>
                )}
              </span>
              <span className="custom_select">
                <Form.Item name="group">
                  <button
                    options={optionsWith}
                    optionType="button"
                    buttonStyle="solid"
                    onClick={(e) => {
                      handleAndOperator(e);
                    }}
                    style={{
                      border: "none",
                      borderRadius: "6px",
                      backgroundColor: "#6d5bd0",
                      color: "white",
                      width: "60px",
                    }}
                    htmlType="submit"
                  >
                    AND
                  </button>
                </Form.Item>
              </span>
            </Form>
          </div>

          {/* ====================================================== */}
        </div>
        <Divider style={{ margin: "0px 0 5px 0" }} />
        <div
          style={{
            textAlign: "end",
            paddingRight: "20px",
            paddingBottom: "20px",
            paddingTop: "10px",
          }}
        >
          <button
            className="profeci_event_button"
            style={{ height: "25px", marginRight: "15px" }}
            onClick={() => postEventFilter()}
          >
            Save
          </button>
          <button
            className="profeci_event_button"
            style={{ height: "25px" }}
            onClick={() => closeFilter()}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default customFilter;
