import React, { useState, useEffect } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { Select, Input, Tag } from "antd";
import "./what.css";
const { Option } = Select;

const tagRender = (props) => {
  const { label, closable, onClose } = props;

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color={"rgba(111,82,237,80%)"}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        marginBottom: "2px",
        marginTop: "2px",
      }}
    >
      {label}
    </Tag>
  );
};

const selected = ({ chosen, parentCallback }) => {
  console.log(chosen, "CHOSEN");
  const [value, setValue] = useState(chosen.sub);
  const [selections, setSelect] = useState();

  useEffect(() => {
    let tempArray = [];
    if (chosen.subSelection !== "none") {
      chosen.subSelection.map((obj) => {
        let tempObj = {
          label: obj.toUpperCase(),
          value: obj,
        };
        tempArray.push(tempObj);
      });
      setSelect(tempArray);
    }
  }, []);
  return (
    <div>
      <div className="custom">
        <span className="custom_select">
          <Select
            showSearch
            style={{
              width: 200,
            }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            onSelect={(value) => {
              console.log(value);
            }}
            value={chosen.label}
            disabled
          ></Select>
        </span>
        <span className="custom_select">
          <Select
            showSearch
            style={{
              width: 60,
            }}
            value={chosen.equ}
            disabled
          ></Select>
        </span>
        <span className="custom_select">
          {chosen.type === "categorical" ? (
            <Select
              mode="multiple"
              showSearch
              style={{
                width: 150,
              }}
              options={selections}
              value={value}
              onChange={(data) => setValue(data)}
              tagRender={tagRender}
            ></Select>
          ) : (
            <Input style={{ width: "150px" }} value={chosen.num} />
          )}
        </span>
        <span className="custom_select">
          <button
            style={{
              border: "none",
              borderRadius: "6px",
              backgroundColor: "#6d5bd0",
              color: "white",
              width: "60px",
            }}
          >
            AND
          </button>
        </span>
        <span className="custom_select">
          <DeleteFilled
            style={{ color: "#6d5bd0", cursor: "pointer" }}
            onClick={() => parentCallback(chosen)}
          />
        </span>
      </div>
    </div>
  );
};

export default selected;
