import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Input,
  Divider,
  Form,
  Table,
  Tooltip,
  message,
  Spin,
} from "antd";
import Metric from "./metric";
import CustomFilter from "./customFilter";
import EventFilter from "./eventFilter";
import LineCharts from "../../components/charts/linchart";
import FieldCards from "../../components/cards/fieldcards";
import placeholder from "../../assests/whatPlaceholder.PNG";
import { RiseOutlined, FallOutlined } from "@ant-design/icons";
import axios from "axios";
import { ReactComponent as MetricButton } from "../../assests/metricButton.svg";
import { ReactComponent as CustomButton } from "../../assests/customButton.svg";
import { ReactComponent as EventButton } from "../../assests/eventButton.svg";
import { ReactComponent as Average } from "../../assests/medianWhite.svg";
import { ReactComponent as Median } from "../../assests/medianWhite.svg";
import { ReactComponent as Max } from "../../assests/max.svg";
import { ReactComponent as Min } from "../../assests/min.svg";
import { ReactComponent as Sum } from "../../assests/sumWhite.svg";
import moment, { localeData } from "moment";
import average from "../../assests/average.png";
import "./what.css";
import { InfoOutlined, DownOutlined } from "@ant-design/icons";
import { type } from "@testing-library/user-event/dist/type";

const API_URL = process.env.REACT_APP_API_URL;

const createEvent = ({
  parentCallback,
  visibleFlag,
  name,
  metricOptions,
  customOptions,
  eventData,
  mode,
}) => {
  const [metricFilter, setMetricFilter] = useState(false);
  const [eventFilter, setEventFilter] = useState(false);
  const [customFilter, setCustomFilter] = useState(false);
  const [isVisible, setVisible] = useState(visibleFlag);
  const [detailedView, setDetail] = useState(false);
  const [detailedViewSecond, setViewSecond] = useState(false);
  const [secondPeriod, setSecondPeriod] = useState(false);
  const [response, setResponse] = useState();
  const [updatedPayload, setUpdatePayload] = useState({});
  const [compareFlag, setCompareFlag] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [result, setResult] = useState(false);
  const [loadingFlag, setLoading] = useState(false);

  const getMappableData = (dateArray) => {
    let correctData = [];
    dateArray.map((obj) => {
      let tempObj = {
        name: moment(obj.time).date() + " " + moment(obj.time).format("MMM"),
        value: Number(obj.value.toFixed(2)),
      };
      correctData.push(tempObj);
    });
    return correctData;
  };

  function numFormatter(num) {
    if (num < 999) {
      return Math.round(num * 100) / 100;
    }
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  }

  const getOperationIcon = (type) => {
    switch (type) {
      case "sum":
        return <Sum fill="white" />;
      case "min":
        return <Min fill="white" />;
      case "max":
        return <Max fill="white" />;
      case "median":
        return <Median fill="white" />;
      case "average":
        return <Average fill="white" />;
      default:
      // code block
    }
  };

  useEffect(() => {
    console.log("MAPPPP CHANGED", response);
  }, [response]);

  // Side Effects

  const postEvent = async () => {
    try {
      console.log(updatedPayload);
      delete updatedPayload.table_name;
      let dataMet = JSON.parse(localStorage.getItem("metricFilter"));
      let filter = JSON.parse(localStorage.getItem("customFilter"));
      let eventFilter = JSON.parse(localStorage.getItem("eventFilter"));
      filter = filter.map((data) => {
        if (data.type === "numeric") {
          return {
            column: data.value,
            filter_type: "numeric",
            upper_limit: data.num,
            lower_limit: data.num,
          };
        }
        if (data.type === "categorical") {
          return {
            column: data.value,
            filter_type: "categorical",
            values: data.sub,
          };
        }
      });
      let data = {
        ...dataMet,
        ...eventFilter,
        filter,
        event_name: name,
        project_id: 0,
        user_id: 0,
        group_id: 0,
      };
      delete data.table_name;
      let event_config = {
        method: mode === "create" ? "post" : "put",
        url: `${API_URL}/events_mgmt/event`,
        headers: {
          key: "Y6KBUDa97drMc6r5oDlG4aHSoVYulRJb",
          "Content-Type": "application/json",
        },
        data: data,
      };
      console.log(Object.keys(eventFilter).length);
      if (Object.keys(eventFilter).length === 0) {
        message.error("Please select the type of event");
      } else {
        parentCallback();
        axios(event_config)
          .then(function(response) {
            console.log(response.data);
            message.success("Successfully applied event filter");
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    } catch (e) {
      message.error("Failed to apply event filter");
      console.log("err...", e);
    }
  };

  useEffect(() => {
    if (typeof eventData !== "boolean" && eventData !== undefined) {
      setResponse(eventData);
      setTimeout(() => {
        setResult(true);
      }, 2000);
    }
    console.log(eventData);
  }, [eventData]);
  useEffect(() => {
    // returned function will be called on component unmount
    return () => {
      localStorage.setItem("metricFilter", JSON.stringify({}));
      localStorage.setItem("eventFilter", JSON.stringify({}));
      localStorage.setItem("customFilter", JSON.stringify([]));
      parentCallback();
      setResponse({});
      setResult(false);
    };
  }, []);

  useEffect(() => {
    console.log(response, result);
  }, [response]);

  return (
    <div>
      <Modal
        title={<div style={{ textAlign: "center" }}>{name}</div>}
        centered
        visible={isVisible}
        onOk={() => {
          postEvent();
        }}
        onCancel={() => parentCallback()}
        maskClosable={true}
        width={1200}
      >
        <div className="eventModal" style={{ zIndex: 1 }}>
          <div
            style={{
              display: "flex",
              position: "relative",
              zIndex: 2,
            }}
          >
            <div>
              <Button
                onClick={() => {
                  setMetricFilter(!metricFilter);
                  setEventFilter(false);
                  setCustomFilter(false);
                  setDisabled(false);
                }}
                className="event_filters"
                style={{ position: "absolute", left: "50px" }}
              >
                <span className="button_icon">
                  <MetricButton fill="#a6acbe" />
                </span>
                Metric
              </Button>
              {metricFilter && (
                <div>
                  <Metric
                    parentCallback={(data) => {
                      setResult(true);
                      setMetricFilter(false);
                      console.log("GOT NEW RESPONSE", data);
                      setResponse(data);
                    }}
                    metricList={metricOptions}
                    tableName="tumor_size"
                    targetId="0"
                    updatePayload={(data) => {
                      setUpdatePayload(data);
                    }}
                    closeFilter={() => setMetricFilter(false)}
                    config={updatedPayload}
                    setLoaders={(flag) => setLoading(flag)}
                  />
                </div>
              )}
            </div>
            <div>
              <Button
                className="event_filters"
                style={{ position: "absolute", left: "190px" }}
                onClick={() => {
                  setCustomFilter(!customFilter);
                  setEventFilter(false);
                  setMetricFilter(false);
                }}
                disabled={!result}
              >
                <span className="button_icon">
                  <CustomButton fill="#a6acbe" />
                </span>
                Custom Filter
              </Button>
              {customFilter && (
                <div>
                  <CustomFilter
                    parentCallback={(data) => {
                      setCustomFilter(false);
                      console.log("GOT NEW RESPONSE", data);
                      setResponse(data);
                    }}
                    closeFilter={() => setCustomFilter(false)}
                    customOptions={customOptions}
                    postData={updatedPayload}
                    updatePayload={(data) => {
                      setUpdatePayload(data);
                    }}
                    setLoaders={(flag) => setLoading(flag)}
                  />
                </div>
              )}
            </div>
            <div>
              <Button
                className="event_filters"
                style={{ position: "absolute", left: "390px" }}
                onClick={() => {
                  setEventFilter(!eventFilter);
                  setMetricFilter(false);
                  setCustomFilter(false);
                }}
                disabled={!result}
              >
                <span className="button_icon">
                  <EventButton fill="#a6acbe" />
                </span>
                Event Type
              </Button>
              {eventFilter && (
                <div>
                  <EventFilter
                    closeFilter={() => setEventFilter(false)}
                    comparePeriods={(data) => {
                      setResponse(data);
                      setSecondPeriod(true);
                      setCompareFlag(true);
                      console.log(data);
                    }}
                    currentResponse={response}
                    postData={updatedPayload}
                    updatePayload={(data) => {
                      setUpdatePayload(data);
                    }}
                    start={
                      response.first_period.time_series_chart.header.startTime
                    }
                    end={response.first_period.time_series_chart.header.endTime}
                    setLoaders={(flag) => setLoading(flag)}
                    mode={mode}
                  />
                </div>
              )}
            </div>
            {/* <div className="event_details">
              <Tooltip title="Details">
                <InfoOutlined
                  onClick={() => setDetail(!detailedView)}
                  className="detail_icon"
                />
              </Tooltip>
            </div> */}
          </div>

          {result && response !== undefined ? (
            <div
              style={{
                // height: "65vh",
                width: "95%",
                position: "absolute",
                top: "100px",
                zIndex: 1,
                display: "flex",
                flexDirection: "row",
                padding: "10px",
                flexWrap: "wrap",
                overflow: "hidden",
                "overflow-y": "scroll",
                height: "66%",
              }}
            >
              {(compareFlag || mode === "edit") && (
                <>
                  <div
                    style={{
                      width: "95%",
                      position: "relative",
                      top: "9px",
                      left: "35px",
                      zIndex: -1,
                      display: "flex",
                      flexDirection: "row",
                      padding: "10px",
                      flexWrap: "wrap",
                      marginTop: "15px",
                      height: "50px",
                      marginBottom: "10px",
                    }}
                  >
                    <h1 style={{ color: "#6D5BD0" }}>
                      {numFormatter(response.event_summary_number)}
                    </h1>{" "}
                    <h3
                      style={{
                        color: "#969595",
                        position: "relative",
                        top: "10px",
                        left: "10px",
                      }}
                    >
                      {response.label}
                    </h3>
                  </div>
                </>
              )}
              <div
                style={{
                  width: "45%",
                  height: "370px",
                  marginTop: "30px",
                  marginLeft: "30PX",
                  border: "#EFEEEB 2px solid",
                  borderRadius: "7px",
                  padding: "20px 10px 20px 30px",
                  boxSizing: "border-box",
                  "box-shadow":
                    "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
                }}
              >
                {!loadingFlag ? (
                  <>
                    <div className="graph_header">
                      <span className="average_icon">
                        {getOperationIcon(response.unit_aggregation)}
                      </span>
                      <span
                        style={{
                          position: "relative",
                          top: "10px",
                          left: "10px",
                          color: "#606060",
                        }}
                      >
                        {response.first_period.time_operation !== undefined
                          ? response.first_period.time_operation.toUpperCase()
                          : ""}
                      </span>

                      <h3
                        style={{
                          textAlign: "center",
                          width: "60%",
                          marginTop: "10px",
                        }}
                      >
                        {Object.keys(response.second_period).length !== 0
                          ? `${response.metric_name.toUpperCase()}:Previous Period`
                          : response.metric_name.toUpperCase()}
                      </h3>
                    </div>
                    <h2 style={{ marginBottom: "10px" }}>
                      {numFormatter(
                        response.first_period.period_summary_number
                      )}
                    </h2>
                    <LineCharts
                      data={getMappableData(
                        response.first_period.time_series_chart.data
                      )}
                      stroke={"#251FD3"}
                    />{" "}
                  </>
                ) : (
                  <div style={{ height: "90%" }}>
                    <Spin
                      style={{ position: "relative", top: "45%", left: "50%" }}
                    />
                  </div>
                )}

                <button
                  className="event_more_info"
                  onClick={() => setDetail(!detailedView)}
                >
                  <DownOutlined />
                  <span style={{ padding: "0px 3px 0px 3px" }}>More info</span>
                </button>
                {detailedView && (
                  <div className="event_fields_cards">
                    {response.first_period.time_operation !== "min" && (
                      <FieldCards
                        figures={numFormatter(response.first_period.min)}
                        operation={"Min"}
                        diff={"None"}
                        theme="#6F52ED"
                      />
                    )}
                    {response.first_period.time_operation !== "max" && (
                      <FieldCards
                        figures={numFormatter(response.first_period.max)}
                        operation={"Max"}
                        diff={"None"}
                        theme="#6F52ED"
                      />
                    )}
                    {response.first_period.time_operation !== "median" && (
                      <FieldCards
                        figures={numFormatter(response.first_period.median)}
                        operation={"Median"}
                        diff={"None"}
                        theme="#6F52ED"
                      />
                    )}
                    {response.first_period.time_operation !== "sum" && (
                      <FieldCards
                        figures={numFormatter(response.first_period.sum)}
                        operation={"Sum"}
                        diff={"None"}
                        theme="#6F52ED"
                      />
                    )}
                    {response.first_period.time_operation !== "average" && (
                      <FieldCards
                        figures={numFormatter(response.first_period.average)}
                        operation={"Average"}
                        diff={"None"}
                        theme="#6F52ED"
                      />
                    )}
                  </div>
                )}
              </div>
              {Object.keys(response.second_period).length !== 0 && (
                <div
                  style={{
                    width: "45%",
                    height: "370px",
                    marginTop: "30px",
                    marginLeft: "30PX",
                    border: "#EFEEEB 2px solid",
                    borderRadius: "7px",
                    padding: "20px 10px 20px 30px",
                    boxSizing: "border-box",
                    "box-shadow":
                      "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
                  }}
                >
                  {!loadingFlag ? (
                    <>
                      <div className="graph_header">
                        <span className="average_icon">
                          <img
                            src={average}
                            alt="average"
                            style={{ height: "20px" }}
                          />
                        </span>
                        <span
                          style={{
                            position: "relative",
                            top: "10px",
                            left: "10px",
                            color: "#606060",
                          }}
                        >
                          {response.second_period.time_operation.toUpperCase()}
                          {/* AVERAGE */}
                        </span>

                        <h3
                          style={{
                            textAlign: "center",
                            width: "60%",
                            marginTop: "10px",
                          }}
                        >
                          {response.metric_name.toUpperCase()}: Recent Period
                        </h3>
                      </div>
                      <h2 style={{ marginBottom: "10px" }}>
                        {numFormatter(
                          response.second_period.period_summary_number
                        )}
                        <span
                          className="range_diff"
                          style={{
                            color:
                              response.second_period.summary_per_diff > 0
                                ? "#33C8A5"
                                : "#FF2626",
                            fontSize: "14px",
                            fontWeight: "normal",
                            marginLeft: "6px",
                            fontWeight: "500",
                          }}
                        >
                          {response.second_period.summary_per_diff > 0 ? (
                            <RiseOutlined />
                          ) : (
                            <FallOutlined />
                          )}
                          {Math.abs(
                            numFormatter(
                              response.second_period.summary_per_diff
                            )
                          )}
                          % from previous period
                        </span>
                      </h2>
                      <LineCharts
                        data={getMappableData(
                          response.second_period.time_series_chart.data
                        )}
                        stroke={"#EDC252"}
                      />
                    </>
                  ) : (
                    <div style={{ height: "90%" }}>
                      <Spin
                        style={{
                          position: "relative",
                          top: "45%",
                          left: "50%",
                        }}
                      />
                    </div>
                  )}
                  <button
                    className="event_more_info"
                    onClick={() => setViewSecond(!detailedViewSecond)}
                    style={{ backgroundColor: "#EDC252" }}
                  >
                    <DownOutlined />
                    <span style={{ padding: "0px 3px 0px 3px" }}>
                      More info
                    </span>
                  </button>
                  {detailedViewSecond && (
                    <div className="event_fields_cards">
                      {response.second_period.time_operation !== "min" && (
                        <FieldCards
                          figures={numFormatter(response.second_period.min)}
                          operation={"Min"}
                          diff={
                            secondPeriod
                              ? response.second_period.min_per_diff
                              : "None"
                          }
                          theme="#EDC252"
                        />
                      )}
                      {response.second_period.time_operation !== "max" && (
                        <FieldCards
                          figures={numFormatter(response.second_period.max)}
                          operation={"Max"}
                          diff={
                            secondPeriod
                              ? response.second_period.max_per_diff
                              : "None"
                          }
                          theme="#EDC252"
                        />
                      )}
                      {response.second_period.time_operation !== "median" && (
                        <FieldCards
                          figures={numFormatter(response.second_period.median)}
                          operation={"Median"}
                          diff={
                            secondPeriod
                              ? response.second_period.median_per_diff
                              : "None"
                          }
                          theme="#EDC252"
                        />
                      )}
                      {response.second_period.time_operation !== "sum" && (
                        <FieldCards
                          figures={numFormatter(response.second_period.sum)}
                          operation={"Sum"}
                          diff={
                            secondPeriod
                              ? response.second_period.sum_per_diff
                              : "None"
                          }
                          theme="#EDC252"
                        />
                      )}
                      {response.second_period.time_operation !== "average" && (
                        <FieldCards
                          figures={numFormatter(response.second_period.average)}
                          operation={"Average"}
                          diff={
                            secondPeriod
                              ? response.second_period.average_per_diff
                              : "None"
                          }
                          theme="#EDC252"
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : mode === "create" ? (
            <div
              className="placeHolder"
              style={{ position: "absolute", top: "25vh" }}
            >
              <h1>Heads up</h1>
              <p>
                You haven’t created any events yet. <br />
                Dial in your settings to begin examining your data!
              </p>
              <img
                src={placeholder}
                alt="Placeholder"
                style={{ height: "200px" }}
              />
            </div>
          ) : (
            <div
              className="placeHolder"
              style={{ position: "relative", top: "45%" }}
            >
              <Spin />
              <p
                style={{
                  color: "#a6acbe",
                  fontWeight: "15px",
                  margin: "10px",
                }}
              >
                FETCHING DATA...
              </p>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default createEvent;
