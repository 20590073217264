import React, { useState } from "react";
import moment from "moment";
import { Divider } from "antd";
import { RiseOutlined, FallOutlined } from "@ant-design/icons";
import Pulse from "react-reveal/Pulse";

const eventCard = (props) => {
  // console.log(Object.keys(props.cardObject.second_period));
  const [periodName, setPeriodName] = useState("Time Period");
  const [fullView, setView] = useState(false);

  function numFormatter(num) {
    if (num < 999) {
      return Math.round(num * 100) / 100;
    }
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  }
  return (
    <>
      <Pulse>
        <div
          style={{
            margin: "20px",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
            borderRadius: "7px",
            overflow: "hidden",
            cursor: "pointer",
            height: "fit-content",
          }}
          onClick={() => setView(!fullView)}
        >
          <h2
            style={{
              margin: 0,
              backgroundColor: "#F4F7FC",
              padding: "5px",
              paddingLeft: "20px",
            }}
          >
            {props.cardObject.event_name}
          </h2>

          {props.cardObject.event_type === "compare_time_periods" ? (
            <div
              style={{
                paddingLeft: "16px",
                position: "relative",
                top: "8px",
                marginBottom: "3px",
                fontSize: "30px",
                fontWeight: "700",
              }}
            >
              {props.cardObject.event_summary_number < 0 ? (
                <span style={{ color: "#FF2626", letterSpacing: "0.5px" }}>
                  {numFormatter(
                    Math.abs(props.cardObject.event_summary_number)
                  )}
                </span>
              ) : (
                <span style={{ color: "#33C8A5" }}>
                  {numFormatter(props.cardObject.event_summary_number)}
                </span>
              )}
            </div>
          ) : (
            <div>
              <span
                style={{
                  color: "#6F52ED",
                  paddingLeft: "16px",
                  position: "relative",
                  top: "8px",
                  marginBottom: "3px",
                  fontSize: "30px",
                  fontWeight: "700",
                }}
              >
                {numFormatter(props.cardObject.event_summary_number)}
              </span>
            </div>
          )}
          <p
            style={{
              fontSize: "12px",
              color: "#969595",
              margin: "0px",
              paddingLeft: "18px",
            }}
          >
            {props.cardObject.event_type === "compare_time_periods"
              ? "Difference in avg performance"
              : props.cardObject.event_type === "anomaly_analysis"
              ? ` median-sum performance`
              : " Avg performance"}
          </p>
          <Divider style={{ margin: "5px 0 5px 0px" }} />
          <div className="card_container">
            <div className="event_period">
              <p className="period_heading">
                {Object.keys(props.cardObject.second_period).length === 0
                  ? periodName
                  : "Previous Period"}
              </p>
              <p className="period_date">
                {moment(props.cardObject.first_period.period_start_date).format(
                  " MMM Do YYYY"
                )}{" "}
                to{" "}
                {moment(props.cardObject.first_period.period_start_date).format(
                  "MMM Do YYYY"
                )}
              </p>
              <div className="event_period_average">
                {numFormatter(
                  props.cardObject.first_period.period_summary_number
                )}
              </div>

              {fullView && (
                <div>
                  <Divider
                    type="horizontal"
                    style={{ marginTop: "5px", marginBottom: "5px" }}
                  />
                  <div className="label_value">
                    <div className="period_label">Min</div>
                    <div className="period_value">
                      {numFormatter(props.cardObject.first_period.min)}
                    </div>
                  </div>
                  <div className="label_value">
                    <div className="period_label">Max</div>
                    <div className="period_value">
                      {numFormatter(props.cardObject.first_period.max)}
                    </div>
                  </div>
                  <div className="label_value">
                    <div className="period_label">Median</div>
                    <div className="period_value">
                      {numFormatter(props.cardObject.first_period.median)}
                    </div>
                  </div>
                  <div className="label_value">
                    <div className="period_label">Average</div>
                    <div className="period_value">
                      {numFormatter(props.cardObject.first_period.average)}
                    </div>
                  </div>
                  <Divider
                    type="horizontal"
                    style={{ marginTop: "5px", marginBottom: "5px" }}
                  />
                  <div className="label_value">
                    <div className="period_label">Sum</div>
                    <div className="period_value">
                      {numFormatter(props.cardObject.first_period.sum)}
                    </div>
                  </div>
                  <div className="label_value">
                    <div className="period_label">Rows</div>
                    <div className="period_value">
                      {numFormatter(props.cardObject.first_period.rows)}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {Object.keys(props.cardObject.second_period).length !== 0 && (
              <Divider
                type="vertical"
                style={{
                  height: fullView ? "220px" : "90px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              />
            )}
            {Object.keys(props.cardObject.second_period).length !== 0 && (
              <div className="event_period">
                {" "}
                <p className="period_heading">
                  {Object.keys(props.cardObject.second_period).length === 0
                    ? periodName
                    : "Recent Period"}{" "}
                </p>
                <p className="period_date">
                  {moment(
                    props.cardObject.second_period.period_start_date
                  ).format("MMM Do YYYY")}{" "}
                  to{" "}
                  {moment(
                    props.cardObject.second_period.period_end_date
                  ).format(" MMM Do YYYY")}
                </p>
                <div className="event_period_average">
                  {" "}
                  {numFormatter(
                    props.cardObject.second_period.period_summary_number
                  )}
                  {props.cardObject.second_period.summary_per_diff !== null ? (
                    props.cardObject.second_period.summary_per_diff > 0 ? (
                      <span
                        style={{
                          color: "#33C8A5",
                          fontSize: "13px",
                          fontWeight: "normal",
                          marginLeft: "6px",
                          fontWeight: "500",
                        }}
                      >
                        <RiseOutlined />
                        {numFormatter(
                          props.cardObject.second_period.summary_per_diff
                        )}
                        %
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "red",
                          fontSize: "13px",
                          fontWeight: "normal",
                          marginLeft: "6px",
                          fontWeight: "500",
                        }}
                      >
                        <FallOutlined />
                        {numFormatter(
                          props.cardObject.second_period.summary_per_diff
                        )}
                        %
                      </span>
                    )
                  ) : (
                    ""
                  )}
                </div>
                {fullView && (
                  <div>
                    {" "}
                    <Divider
                      type="horizontal"
                      style={{ marginTop: "5px", marginBottom: "5px" }}
                    />
                    <div className="label_value">
                      <div className="period_label">Min</div>
                      <div className="period_value">
                        {numFormatter(props.cardObject.second_period.min)}
                      </div>
                    </div>
                    <div className="label_value">
                      <div className="period_label">Max</div>
                      <div className="period_value">
                        {numFormatter(props.cardObject.second_period.max)}
                      </div>
                    </div>
                    <div className="label_value">
                      <div className="period_label">Median</div>
                      <div className="period_value">
                        {numFormatter(props.cardObject.second_period.median)}
                      </div>
                    </div>
                    <div className="label_value">
                      <div className="period_label">Average</div>
                      <div className="period_value">
                        {numFormatter(props.cardObject.second_period.average)}
                      </div>
                    </div>
                    <Divider
                      type="horizontal"
                      style={{ marginTop: "5px", marginBottom: "5px" }}
                    />
                    <div className="label_value">
                      <div className="period_label">Sum</div>
                      <div className="period_value">
                        {numFormatter(props.cardObject.second_period.sum)}
                      </div>
                    </div>
                    <div className="label_value">
                      <div className="period_label">Rows</div>
                      <div className="period_value">
                        {numFormatter(props.cardObject.second_period.rows)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Pulse>
    </>
  );
};

export default eventCard;
