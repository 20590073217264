import React, { useState, useLayoutEffect } from "react";
import {
  Select,
  Button,
  Tooltip,
  Modal,
  Checkbox,
  Divider,
  message,
  Progress,
  notification,
  Radio,
  Space,
  Spin,
} from "antd";
import "../../components/buttons/buttons.css";
import { ReactComponent as Run } from "../../assests/run.svg";
import placeholder from "../../assests/whatPlaceholder.PNG";
import "../whyBoard/why.css";
import "./whatIf.css";
import SummaryTree from "../whyBoard/summaryTree";
import SummaryTable from "../whyBoard/summaryTable";
import {
  summaryTree,
  why_table,
  event1,
  event2,
  something,
} from "../whyBoard/dummy";
import EventCard from "../whenBoard/eventCard";
import Areachart from "../../components/areaChart";
import Comparetimeseries from "../../components/compareTime";
import { useEffect } from "react";
import { colors } from "../../components/colorScheme";

const whatIf = () => {
  const legendData = [
    {
      key: "action_0",
      label: "treatment =  treatment 1 ",
    },
    {
      key: "action_1",
      label: "treatment =  treatment 2 ",
    },
    {
      key: "action_2",
      label: "treatment =  treatment 0 ",
    },
  ];
  const { Option } = Select;

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const [noEventFlag, setEventFlag] = useState(true);
  const [running, setRunning] = useState(false);
  const [explore, setExplore] = useState(false);
  const [exploreResult, setExploreResult] = useState(false);
  const [horizontalLegend, setLegendOrientation] = useState(true);

  useEffect(() => {
    console.log(something.histogram);
  }, [something]);

  //  CUSTOM_HOOK_CHECKING_SIZE
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  const empty = true;

  const [width, height] = useWindowSize();

  useEffect(() => {
    console.log(width, height);
    if (width < 1300) {
      setLegendOrientation(false);
    } else {
      setLegendOrientation(true);
    }
  }, [width, height]);

  return (
    <div className="whatIf_board">
      <div className="whatIf_controls">
        <div className="control_select">
          {" "}
          <h3>Event List</h3>
          <Select
            showSearch
            placeholder="Select a event"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option value="1">Event 1</Option>
            <Option value="2">Event 2</Option>
            <Option value="3">Event 3</Option>
          </Select>
        </div>
        <div>
          <button
            className="profeci_event_button"
            onClick={() => {
              console.log("RUN");
              setEventFlag(!noEventFlag);
            }}
          >
            <Run className="run_icon" fill="white" />
            <span className="run_title">RUN</span>
          </button>
          <span className="control_message">Never Run Before</span>
        </div>
      </div>
      {noEventFlag && !running ? (
        <div className="why_placeholder">
          <h1>Heads up</h1>
          <p>
            You haven’t selected any event yet. <br />
            Please make a selection!
          </p>
          <img
            src={placeholder}
            alt="Placeholder"
            style={{ height: "200px" }}
          />
        </div>
      ) : running ? (
        <div className="loading_run">
          <Spin />
        </div>
      ) : null}
      {!noEventFlag && (
        <div className="why_summary">
          <div className="why_summary_header">SUBPOPULATIONS SUMMARY</div>
          <div className="summary_tree_container">
            <SummaryTree dataSource={summaryTree} />
          </div>
        </div>
      )}
      {!noEventFlag && (
        <div className="why_summary" style={{ marginTop: "30px" }}>
          <div className="why_summary_header">WHAT VS. WHAT IF</div>
          <h3 className="container_headers">What happened</h3>
          <div className="whatIf_summary_table_container">
            <div className="whatIf_summary_table">
              <SummaryTable dataSource={why_table} />
            </div>
            <div className="whatIf_summary_card">
              <EventCard cardObject={event1} />
            </div>
          </div>
          <h3 className="container_headers">
            What if (minimum attainable total)
          </h3>
          <div className="whatIf_summary_table_container">
            <div className="whatIf_summary_table">
              <SummaryTable dataSource={why_table} />
            </div>
            <div className="whatIf_summary_card">
              <EventCard cardObject={event2} />
            </div>
          </div>
        </div>
      )}
      {!noEventFlag && (
        <div
          className="why_summary"
          style={{
            marginTop: "30px",
            padding: "20px",
          }}
        >
          <div className="why_summary_header">EXPLORE WHAT IF SCENARIOS</div>
          <div style={{ padding: "20px" }}>
            <button
              className="profeci_event_button"
              onClick={() => {
                console.log("EXPLORE");
                setExplore(true);
              }}
            >
              <Run className="run_icon" fill="white" />
              <span className="run_title">NEW SCENARIO</span>
            </button>
          </div>
          {exploreResult && (
            <div className="explore_result">
              <div className="whatIf_summary_table_container">
                <div className="whatIf_summary_table">
                  <SummaryTable dataSource={why_table} />
                </div>
                <div className="whatIf_summary_card">
                  <EventCard cardObject={event2} />
                </div>
              </div>
              {!horizontalLegend && (
                <div className="legend_horizontal">
                  {" "}
                  {legendData.map((y, index) => {
                    return (
                      <>
                        <div className="legend_sub">
                          <p className="legend_label">
                            <p>{y.key.toUpperCase()}</p>
                            <div
                              className="legend_color"
                              style={{
                                backgroundColor: colors[index],
                                border: `solid 2px ${colors[index]}`,
                              }}
                            ></div>
                            <span style={{ color: colors[index] }}>
                              {y.label.toUpperCase()}
                            </span>
                          </p>
                        </div>
                      </>
                    );
                  })}
                </div>
              )}
              <div className="split_overview">
                <div className="dive_distribution">
                  <h3
                    style={{
                      fontWeight: "700",
                      textTransform: "uppercase",
                      marginBottom: "20px",
                    }}
                  >
                    Distributions overview
                  </h3>
                  <div className="chart_containers">
                    <Areachart
                      areaData={something.histogram.data}
                      actions={something.time_series.header.data_keys}
                    />
                  </div>
                </div>

                <div className="dive_time_series">
                  <h3
                    style={{
                      fontWeight: "700",
                      textTransform: "uppercase",
                      marginBottom: "20px",
                    }}
                  >
                    Time series overview
                  </h3>
                  <div className="chart_containers">
                    <Comparetimeseries
                      lineData={something.time_series.data}
                      actions={something.time_series.header.data_keys}
                    />
                  </div>
                </div>
              </div>
              {horizontalLegend && (
                <div className="dive_legend dive_whatIf_legend">
                  {legendData.map((y, index) => {
                    return (
                      <>
                        <div className="legend_sub">
                          <p className="legend_label">
                            <p>{y.key.toUpperCase()}</p>
                            <div
                              className="legend_color"
                              style={{
                                backgroundColor: colors[index],
                                border: `solid 2px ${colors[index]}`,
                              }}
                            ></div>
                            <span style={{ color: colors[index] }}>
                              {y.label.toUpperCase()}
                            </span>
                          </p>
                        </div>
                      </>
                    );
                  })}
                </div>
              )}
              {/* <div className="dive_legend">
                {legendData.map((y, index) => {
                  return (
                    <>
                      <div className="legend_sub">
                        <p className="legend_label">
                          <p>{y.key.toUpperCase()}</p>
                          <div
                            className="legend_color"
                            style={{
                              backgroundColor: colors[index],
                              border: `solid 2px ${colors[index]}`,
                            }}
                          ></div>
                          <span style={{ color: colors[index] }}>
                            {y.label.toUpperCase()}
                          </span>
                        </p>
                      </div>
                      {index !== legendData.length - 1 && (
                        <Divider type="vertical" className="divider_legend" />
                      )}
                    </>
                  );
                })}
              </div> */}
            </div>
          )}
        </div>
      )}

      <Modal
        title="EDIT SCENARIO"
        visible={explore}
        onOk={() => {
          setExplore(false);
          setExploreResult(true);
        }}
        onCancel={() => setExplore(false)}
        width="400px"
      >
        <div className="line_sub_sel">
          <h3>Subpopulation 1 </h3>
          <Select className="select_sub_sum" placeholder="Select Action">
            <Option>Action 1</Option>
            <Option>Action 2</Option>
            <Option>Action 3</Option>
          </Select>
        </div>
        <div className="line_sub_sel">
          <h3>Subpopulation 2 </h3>
          <Select className="select_sub_sum" placeholder="Select Action">
            <Option>Action 1</Option>
            <Option>Action 2</Option>
            <Option>Action 3</Option>
          </Select>
        </div>
        <div className="line_sub_sel">
          <h3>Subpopulation 3 </h3>
          <Select className="select_sub_sum" placeholder="Select Action">
            <Option>Action 1</Option>
            <Option>Action 2</Option>
            <Option>Action 3</Option>
          </Select>
        </div>
      </Modal>
    </div>
  );
};

export default whatIf;
