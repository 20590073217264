import React, { useEffect, useState } from "react";
import { colors } from "./colorScheme";
import { PieChart, Pie, Cell, Sector, ResponsiveContainer } from "recharts";

const Divepie = ({ data }) => {
  console.log("PIE_DATA", data);
  const COLORS = [
    "rgba(235,204,44,0.8)",
    "rgba(109,91,208,0.8)",
    "rgba(127, 183, 126,0.8)",
  ];
  const strokeArray = ["#EBCC2C", "#6D5BD0", "rgb(127, 183, 126)"];
  const pieData = {
    total: 523,
    data: [
      {
        name: "action_1",
        value: 100,
      },
      {
        name: "action_2",
        value: 423,
      },
    ],
  };
  const [pieChartData, setPieChart] = useState([]);
  const [activeIndex, setActive] = useState(0);

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`Value ${value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const onPieEnter = (_, index) => {
    setActive(index);
  };

  useEffect(() => {
    console.log(data);
    let tempArray = [];
    tempArray = data.map((x) => {
      return { name: x.label, value: x.count };
    });
    setPieChart(tempArray);
    console.log(tempArray);
  }, []);

  return (
    <ResponsiveContainer>
      <PieChart width={350} height={400}>
        <Pie
          activeIndex={activeIndex}
          data={pieChartData}
          color="#000000"
          dataKey="value"
          nameKey="name"
          innerRadius={80}
          outerRadius={120}
          fill="#8884d8"
          cx="50%"
          cy="50%"
          paddingAngle={2}
          activeShape={renderActiveShape}
          onMouseEnter={onPieEnter}
        >
          {pieChartData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colors[index]}
              stroke={colors[index]}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default Divepie;
