import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Input,
  Divider,
  Form,
  Table,
  message,
  Spin,
  Tooltip,
  notification,
} from "antd";
import placeholder from "../../assests/whatPlaceholder.PNG";
import "./what.css";
import Metric from "./metric";
import CustomFilter from "./customFilter";
import EventFilter from "./eventFilter";
import EventCard from "./eventCard";
import moment from "moment";
import "../../components/buttons/buttons.css";
import {
  PlusCircleOutlined,
  FilterFilled,
  LoadingOutlined,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import CreateEvent from "./createEvent";
import axios from "axios";

import EventButton from "../../components/buttons/eventButton";

import { events } from "./eventTable";
import { eventCards } from "./eventCardDummy";

const API_URL = process.env.REACT_APP_API_URL;

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
const { Search } = Input;

const whenBoard = () => {
  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: type.toUpperCase(),
      description: description,
    });
  };
  const columns = [
    {
      title: "Actions",
      width: 100,
      fixed: true,
      render: (record) => {
        return (
          <span>
            <Tooltip title="Edit">
              <EditFilled
                style={{
                  fontSize: "14px",
                  color: "#6D5BD0",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEditFlag(true);
                  getMetricOptions();
                  getCustomOptions();
                  getEventView(record.event_name);
                  setEditName(record.event_name);
                }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteFilled
                style={{
                  fontSize: "14px",
                  color: "#6D5BD0",
                  position: "relative",
                  left: "20px",
                }}
                onClick={() => {
                  setDeleteFlag(true);
                  setDeleteEvent(record);
                }}
              />
            </Tooltip>
          </span>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "event_name",
      key: "event_name",
      fixed: true,
      width: 150,
    },
    {
      title: "Event Type",
      dataIndex: "event_type",
      key: "event_type",
      width: 150,
      render: (event_type) => {
        return event_type === "compare_time_periods"
          ? "Compare Time Periods"
          : event_type === "anomaly_analysis"
          ? "Anomaly Analysis"
          : "General Performance";
      },
    },
    {
      title: "Date",
      key: "date",
      width: 550,
      render: (record) => {
        console.log(record);
        return (
          <>
            <span
              style={{
                backgroundColor: "rgba(237,194,82,0.3)",
                padding: "2px",
                borderRadius: "5px",
              }}
            >
              {moment(record.first_period_start_date).format(
                "ddd, MMM Do YYYY"
              )}{" "}
              to{" "}
              {moment(record.first_period_start_date).format(
                "ddd, MMM Do YYYY"
              )}
            </span>

            {record.second_period_start_date !== null && (
              <span
                style={{
                  backgroundColor: "rgba(111,82,237,0.3)",
                  padding: "2px",
                  borderRadius: "5px",
                  position: "relative",
                  left: "5px",
                }}
              >
                {moment(record.second_period_start_date).format(
                  "ddd, MMM Do YYYY"
                )}{" "}
                to{" "}
                {moment(record.second_period_start_date).format(
                  "ddd, MMM Do YYYY"
                )}
              </span>
            )}
          </>
        );
      },
    },
    {
      title: "Unit Operation",
      key: "unit",
      width: 150,
      render: (record) => {
        return record.unit_operation.toUpperCase();
      },
    },
    {
      title: "Time Operation",
      key: "time",
      width: 150,
      render: (record) => {
        return record.time_operation.toUpperCase();
      },
    },
    {
      title: "Filters",
      key: "filter",
      width: 300,
      render: (record) => {
        return (
          <>
            {record.filter !== null ? (
              record.filter.map((filter) => {
                console.log(filter);
                if (filter.filter_type === "categorical") {
                  return (
                    <>
                      <span
                        style={{
                          backgroundColor: "rgba(111,82,237,0.3)",
                          padding: "2px",
                          borderRadius: "5px",
                          position: "relative",
                          marginLeft: "2px",
                          marginRight: "2px",
                        }}
                      >
                        {` ${filter.column.toUpperCase()} = ${filter.values}`}{" "}
                      </span>
                    </>
                  );
                }
                if (filter.filter_type === "range") {
                  return (
                    <span
                      style={{
                        backgroundColor: "rgba(111,82,237,0.3)",
                        padding: "2px",
                        borderRadius: "5px",
                        position: "relative",
                        marginLeft: "2px",
                        marginRight: "2px",
                        left: "5px",
                      }}
                    >
                      {` ${
                        filter.lower_limit !== null
                          ? filter.lower_limit + " < "
                          : ""
                      } ${filter.column.toUpperCase()} ${
                        filter.upper_limit !== null
                          ? " < " + filter.upper_limit
                          : ""
                      }`}
                    </span>
                  );
                }
              })
            ) : (
              <span>None</span>
            )}
          </>
        );
      },
    },
  ];
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [editView, setEditView] = useState(false);
  const [deleteEvent, setDeleteEvent] = useState({});
  const [eventObject, setEvent] = useState(false);
  const [eventName, setEventName] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableheld, setHold] = useState([]);
  const [noEvents, setEvents] = useState(false);
  const [eventCardsData, setEventCards] = useState([]);
  const [errorName, setError] = useState(false);
  const [spinFlag, setSpinFlag] = useState(false);
  const [editEventName, setEditName] = useState("Edit");
  const [tableLoadingFlag, setTableLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 1,
  });

  // useEffect(() => {
  //   setTableData(events.events);
  //   console.log(events.events);
  //   let mappedData = [];
  //   let rawData = [...events.events];
  //   rawData.map((x) => {
  //     console.log(x);
  //   });
  //   setTableData(events.events);
  // }, [events]);

  // useEffect(() => {
  //   console.log(eventCards.events);
  //   let eveCards = eventCards.events;
  //   setEventCards(eveCards);
  //   eveCards.map((x) => {
  //     console.log(x);
  //   });
  // }, [eventCards]);

  const handleVisible = () => {
    setVisible(false);
    setEventName(false);
    setTimeout(() => {
      getEventSummary(0);
    }, 1000);
    getEventList(0);
  };

  const handleCreateEvent = () => {
    console.log(eventName);
    if (eventName && eventName !== "") {
      setVisible(true);
      setEvent(false);
    } else {
      setError("Please enter event name");
    }
  };

  const getAvailableOptions = (name) => {
    let available = [];
    customList.map((data) => {
      if (data.name === name) {
        available = data.value_options;
      }
    });
    return available;
  };

  // =========================SIDE EFFECTS ========================

  const getEventSummary = async (projectId) => {
    setSpinFlag(true);
    try {
      let eventsSummaryConfig = {
        method: "get",
        url: `${API_URL}/events_mgmt/events_summary?project_id=${projectId}`,
        headers: {
          key: "Y6KBUDa97drMc6r5oDlG4aHSoVYulRJb",
        },
      };

      axios(eventsSummaryConfig)
        .then(function(response) {
          console.log(JSON.stringify(response.data));
          openNotificationWithIcon(
            "success",
            "Successfully fetched the events summary"
          );
          // message.success("Successfully fetched the events summary");
          setEventCards(response.data.events);
          setSpinFlag(false);
        })
        .catch(function(error) {
          setSpinFlag(false);
          console.log(error);
          openNotificationWithIcon("error", "Failed to fetch events summary");
          // message.error("Failed to fetch events summary");
        });
    } catch (e) {
      openNotificationWithIcon("error", "Failed to fetch the table");
      // message.error("Failed to fetch the table");
      console.log("err...", e);
    }
  };

  const getEventList = async (projectId) => {
    setTableLoading(true);
    try {
      let eventListConfig = {
        method: "get",
        url: `${API_URL}/events_mgmt/list_events?project_id=0`,
        headers: {
          key: "Y6KBUDa97drMc6r5oDlG4aHSoVYulRJb",
        },
      };

      axios(eventListConfig)
        .then(function(response) {
          console.log(JSON.stringify(response.data));
          setTableData(response.data.events);
          setHold(response.data.events);
          openNotificationWithIcon(
            "success",
            "Successfully fetched the list of events"
          );
          // message.success("Successfully fetched the list of events");
          setTableLoading(false);
        })
        .catch(function(error) {
          console.log(error);
          setTableLoading(false);
          openNotificationWithIcon("error", "Failed to fetch the event list");
          // message.error("Failed to fetch the event list");
        });
    } catch (e) {
      console.log("err...", e);
    }
  };

  const [metricList, setMetricList] = useState([]);
  const [customList, setCustomList] = useState([]);
  const getMetricOptions = async (projectId) => {
    try {
      let metric_list_config = {
        method: "get",
        url: `${API_URL}/list_metrics?project_id=0`,
        headers: {
          key: "Y6KBUDa97drMc6r5oDlG4aHSoVYulRJb",
        },
      };

      axios(metric_list_config)
        .then(function(response) {
          console.log(JSON.stringify(response.data.metrics));
          setMetricList(response.data.metrics);
          openNotificationWithIcon(
            "success",
            "Successfully fetched the metrics list"
          );
          // message.success("Successfully fetched the metrics list");
        })
        .catch(function(error) {
          console.log(error);
        });
    } catch (e) {
      openNotificationWithIcon("error", "Failed to fetch the metrics list");
      // message.error("Failed to fetch the metrics list");
      console.log("err...", e);
    }
  };

  const getCustomOptions = async (projectId) => {
    try {
      let custom_list_config = {
        method: "get",
        url: `${API_URL}/filter_options?table_name=tumor_size`,
        headers: {
          key: "Y6KBUDa97drMc6r5oDlG4aHSoVYulRJb",
        },
      };

      axios(custom_list_config)
        .then(function(response) {
          console.log(JSON.stringify(response.data.columns));
          setCustomList(response.data.columns);
          openNotificationWithIcon(
            "success",
            "Successfully fetched the custom list"
          );
          // message.success("Successfully fetched the custom list");
        })
        .catch(function(error) {
          openNotificationWithIcon("error", "Failed to fetch the custom list");
          console.log(error);
        });
    } catch (e) {
      // message.error("Failed to fetch the custom list");
      console.log("err...", e);
    }
  };

  const postDeleteEvent = async (deleteEvent) => {
    var deleteConfig = {
      method: "delete",
      url: `${API_URL}/events_mgmt/event?event_name=${deleteEvent}&project_id=0`,
      headers: {
        key: "Y6KBUDa97drMc6r5oDlG4aHSoVYulRJb",
      },
    };

    axios(deleteConfig)
      .then(function(response) {
        console.log(JSON.stringify(response.data));
        openNotificationWithIcon("success", "Successfully deleted the event");
        // message.success("Successfully deleted the event");
        getEventSummary(0);
        getEventList(0);
      })
      .catch(function(error) {
        openNotificationWithIcon("error", "Failed to delete the event");
        // message.error("Failed to delete the event");
        console.log(error);
      });
  };

  const getEventView = async (name) => {
    var data = "";
    var event_view = {
      method: "get",
      url: `${API_URL}/events_mgmt/event_view?project_id=0&event_name=${name.trim()}`,
      headers: { key: "Y6KBUDa97drMc6r5oDlG4aHSoVYulRJb" },
      data: data,
    };
    axios(event_view)
      .then(function(response) {
        console.log(response.data);
        localStorage.setItem(
          "metricFilter",
          JSON.stringify({
            table_name: "tumor_size",
            project_id: 0,
            metric_name: response.data.metric_name,
            unit_aggregation: response.data.unit_aggregation,
          })
        );
        localStorage.setItem("customFilter", JSON.stringify({}));
        let eventFilterEdit = {};
        if (response.data.event_type === "anomaly_analysis") {
          eventFilterEdit = {
            event_type: "anomaly_analysis",
            event_first_period_end: moment(
              response.data.first_period.period_end_date
            ).format("YYYY-MM-DD"),
            event_first_period_start: moment(
              response.data.first_period.period_start_date
            ).format("YYYY-MM-DD"),
            time_aggregation_anomaly_one: response.data.first_period.time_operation.split(
              " - "
            )[0],
            time_aggregation_anomaly_two: response.data.first_period.time_operation.split(
              " - "
            )[1],
            time_aggregation_anomaly_operation:
              response.data.first_period.time_operation,
          };
        }
        if (response.data.event_type === "compare_time_periods") {
          eventFilterEdit = {
            event_type: "compare_time_periods",
            event_first_period_start: moment(
              response.data.first_period.period_start_date
            ).format("YYYY-MM-DD"),
            event_first_period_end: moment(
              response.data.first_period.period_end_date
            ).format("YYYY-MM-DD"),
            event_second_period_start: moment(
              response.data.second_period.period_start_date
            ).format("YYYY-MM-DD"),
            event_second_period_end: moment(
              response.data.second_period.period_end_date
            ).format("YYYY-MM-DD"),
            time_aggregation: response.data.second_period.time_operation,
          };
        }
        if (response.data.event_type === "general_performance") {
          eventFilterEdit = {
            event_type: "general_performance",
            event_first_period_end: moment(
              response.data.first_period.period_end_date
            ).format("YYYY-MM-DD"),
            event_first_period_start: moment(
              response.data.first_period.period_start_date
            ).format("YYYY-MM-DD"),
            time_aggregation: response.data.first_period.time_operation,
          };
        }
        localStorage.setItem("eventFilter", JSON.stringify(eventFilterEdit));

        let customFilterEdit = response.data.filter.map((data) => {
          if (data.filter_type === "categorical") {
            return {
              equ: "equals",
              label: data.column.toUpperCase(),
              num: "none",
              sub: data.values,
              subSelection: getAvailableOptions(data.column),
              type: "categorical",
              value: data.column,
            };
          }
        });
        localStorage.setItem("customFilter", JSON.stringify(customFilterEdit));
        setEditView(response.data);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getEventSummary(0);
    getEventList(0);
    getMetricOptions(0);
    getCustomOptions(0);
  }, []);

  return (
    <div className="what_board">
      <Modal
        centered
        visible={deleteFlag}
        onOk={() => {
          postDeleteEvent(deleteEvent.event_name);
          setDeleteFlag(false);
        }}
        onCancel={() => {
          setDeleteFlag(false);
        }}
        maskClosable={true}
        width={400}
      >
        <h2>Are you sure to delete the event {deleteEvent.event_name} ? </h2>
      </Modal>
      {isVisible && (
        <CreateEvent
          name={eventName}
          visibleFlag={isVisible}
          parentCallback={() => handleVisible()}
          metricOptions={metricList}
          customOptions={customList}
          mode="create"
        />
      )}
      {editFlag && (
        <CreateEvent
          name={editEventName}
          visibleFlag={editFlag}
          metricOptions={metricList}
          customOptions={customList}
          eventData={editView}
          parentCallback={() => {
            setEditFlag(false);
            setEventName(false);
            getEventSummary(0);
            getEventList(0);
          }}
          mode="edit"
        />
      )}
      <div className="event_section">
        {noEvents && (
          <div className="placeHolder">
            <h1>Heads up</h1>
            <p>
              You haven’t created any events yet. <br />
              Dial in your settings to begin examining your data!
            </p>
            <img
              src={placeholder}
              alt="Placeholder"
              style={{ height: "200px" }}
            />
          </div>
        )}
        <div style={{ position: "absolute" }}>
          <button
            className="profeci_event_button"
            onClick={() => {
              setEvent(!eventObject);
              console.log("Clicked", eventObject);
            }}
            style={{ left: "30px" }}
          >
            <PlusCircleOutlined
              style={{
                fontSize: "24px",
                position: "relative",
                top: "2px",
                marginRight: "5px",
              }}
            />
            <span style={{ position: "relative", top: "-2px" }}>
              {" "}
              New Event
            </span>
          </button>
          {eventObject && (
            <div
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                width: "300px",
                padding: "20px",
                borderRadius: "5px",
              }}
              className="event_name"
            >
              <Form name="eventNameModal">
                <h3>Name</h3>
                <Form.Item
                  name="event_name_create"
                  rules={[
                    { required: true, message: "Please input event name!" },
                  ]}
                >
                  <Input
                    style={{ width: "250px" }}
                    onChange={(e) => setEventName(e.target.value.trim())}
                    autoComplete="off"
                  />
                </Form.Item>
                <Divider style={{ marginTop: "5px", marginBottom: "5px" }} />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button onClick={() => setEvent(false)}>Cancel</Button>
                  <Button
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      handleCreateEvent();
                    }}
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          )}
          <div className="event_card_container">
            {eventCardsData.map((element) => {
              return <EventCard cardObject={element} />;
            })}
            {spinFlag && <Spin className="event_card_loader" />}
            {!spinFlag && eventCardsData.length === 0 && (
              <>
                <div
                  className="placeHolder"
                  style={{ position: "absolute", top: "70px" }}
                >
                  <h1>Heads up</h1>
                  <p>
                    You haven’t created any events yet. <br />
                    Dial in your settings to begin examining your data!
                  </p>
                  <img
                    src={placeholder}
                    alt="Placeholder"
                    style={{ height: "200px" }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="table_section">
        <div className="search_event">
          <span className="event_filter">
            <FilterFilled
              style={{
                fontSize: "12px",
                backgroundColor: "white",
                padding: "6px",
                borderRadius: "5px",
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
              }}
            />
            <Search
              style={{
                width: "300px",
                position: "relative",
                left: "30px",
                borderRadius: "7px",
                top: "-4px",
              }}
              placeholder="Search Event"
              onChange={(e) => {
                const currValue = e.target.value;
                console.log(currValue);
                if (currValue !== "") {
                  const filteredData = tableheld.filter((entry) => {
                    console.log(entry, currValue, "Searching Arrrrrrays");
                    return (
                      entry.event_name
                        .toLowerCase()
                        .includes(currValue.toLowerCase()) ||
                      entry.event_type
                        .replace(/_/g, " ")
                        .includes(currValue.toLowerCase())
                    );
                  });
                  setTableData(filteredData);
                }
                if (currValue === "") {
                  setTableData(tableheld);
                }
              }}
            />
          </span>
        </div>
        <Table
          loading={tableLoadingFlag}
          columns={columns}
          dataSource={tableData}
          scroll={{ x: "50vw", y: 200 }}
        />
      </div>
    </div>
  );
};

export default whenBoard;
