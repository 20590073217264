import React, { useEffect, useState } from "react";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { Input, Checkbox, Divider, Button, message, Spin } from "antd";
import "../../components/buttons/buttons.css";
import { ReactComponent as Average } from "../../assests/average.svg";
import { ReactComponent as Median } from "../../assests/median.svg";
import { ReactComponent as Sum } from "../../assests/sum.svg";

const API_URL = process.env.REACT_APP_API_URL;

const { Search } = Input;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const metric = ({
  parentCallback,
  metricList,
  targetId,
  tableName,
  updatePayload,
  closeFilter,
  config,
  setLoaders,
}) => {
  const tempMetrics = JSON.parse(localStorage.getItem("metricFilter"));
  const [metricName, setMetricName] = useState("");
  const [checkboxGroup, setSelection] = useState({
    average: false,
    median: false,
    sum: false,
  });
  const [metricGroup, setMetricGroup] = useState(
    metricList.map((data) => {
      let label = data.metric_name;
      return { label: false };
    })
  );
  const [unitAggregation, setUnitAgg] = useState();
  const [response, setResponse] = useState({});
  const [payload, setPayload] = useState({
    table_name: "",
    project_id: 0,
    metric_name: "",
    unit_aggregation: "",
  });

  const handleSelection = (data) => {
    setMetricName(data);
  };

  // Side Effects

  useEffect(() => {
    updatePayload(payload);
  }, [payload]);

  const postMetricSelection = async () => {
    let pass = true;
    let dataEvent = JSON.parse(localStorage.getItem("eventFilter"));
    let filter = JSON.parse(localStorage.getItem("customFilter"));
    if (filter.length !== 0) {
      filter = filter.map((data) => {
        if (data !== null) {
          if (data.type === "numeric") {
            return {
              column: data.value,
              filter_type: "numeric",

              upper_limit:
                data.equ === "lesserthanqual" || data.equ === "equals"
                  ? data.num
                  : null,
              lower_limit:
                data.equ === "greaterthanequal" || data.equ === "equals"
                  ? data.num
                  : null,
            };
          }
          if (data.type === "categorical") {
            return {
              column: data.value,
              filter_type: "categorical",
              values: data.sub,
            };
          }
        }
      });
    }
    let tempPayload = { ...payload, filter, ...dataEvent };
    tempPayload.metric_name = metricName;
    tempPayload.unit_aggregation = unitAggregation;
    tempPayload.project_id = targetId;
    tempPayload.table_name = tableName;
    console.log(tempPayload);
    setPayload(tempPayload);
    setLoaders(true);
    if (metricName !== "" && unitAggregation !== undefined) {
      try {
        let metric_config = {
          method: "post",
          url: `${API_URL}/events_mgmt/event_temp_view`,
          headers: {
            key: "Y6KBUDa97drMc6r5oDlG4aHSoVYulRJb",
            "Content-Type": "application/json",
          },
          data: JSON.stringify(tempPayload),
        };

        axios(metric_config)
          .then(function(response) {
            console.log(response.data);
            message.success("Successfully fetched the metrics list");
            parentCallback(response.data);
            localStorage.setItem("metricFilter", JSON.stringify(tempPayload));
            setLoaders(false);
          })
          .catch(function(error) {
            console.log(error);
          });
      } catch (e) {
        message.error("Failed to fetch the metrics list");
        console.log("err...", e);
      }
    } else {
      message.error("Please select metric and operation");
    }
  };

  const handleMetricSelection = (e, value) => {
    if (e.target.checked) {
      setUnitAgg(value);
      setSelection({
        average: value === "average",
        median: value === "median",
        sum: value === "sum",
      });
    }
  };

  useEffect(() => {
    console.log(checkboxGroup);
  }, [checkboxGroup]);

  useEffect(() => {
    console.log(config);
  }, [config]);

  useEffect(() => {
    console.log("Temporary Metric Selection", tempMetrics);
    if (tempMetrics !== null) {
      if (Object.keys(tempMetrics).length !== 0) {
        setMetricName(tempMetrics.metric_name);
        handleMetricSelection(
          { target: { checked: true } },
          tempMetrics.unit_aggregation
        );
      }
    }
  }, []);

  useEffect(() => {
    console.log("Metric Selection Group", metricGroup);
  }, [metricGroup]);

  useEffect(() => {
    console.log(metricName, unitAggregation);
  }, [metricName, unitAggregation]);
  return (
    <div className="metric_filter">
      <div
        style={{
          width: "330px",
          borderRadius: "5px",
          // height: "300px",
          boxShadow:
            "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
          //   border: "solid 2px black",
          position: "relative",
          zIndex: "1000",
          backgroundColor: "white",
        }}
      >
        <h4
          style={{
            backgroundColor: "#F4F7FC",
            color: "black",
            padding: "10px",
            margin: "0px",
            borderRadius: "5px",
          }}
        >
          Edit Metric
        </h4>
        <div
          style={{
            width: "100%",
            height: "fit-content",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "50%",
              height: "80%",
              position: "relative",
              padding: "10px",
              boxSizing: "border-box",
            }}
          >
            <Search
              placeholder="Search"
              // onSearch={onSearch}
              style={{ width: 140 }}
            />
            <Checkbox style={{ display: "none" }}></Checkbox>
            {metricList.length !== 0 ? (
              metricList.map((ele) => {
                return (
                  <div className="label_container">
                    <span className="metric_label">
                      {ele.metric_name.toUpperCase()}
                    </span>
                    <Checkbox
                      className="metric_options"
                      onChange={(e) =>
                        handleSelection(
                          e.target.checked ? ele.metric_name : "none"
                        )
                      }
                      checked={ele.metric_name === metricName}
                    ></Checkbox>
                  </div>
                );
              })
            ) : (
              <Spin indicator={antIcon} className="metric_spinner" />
            )}
          </div>
          <div
            style={{
              width: "50%",
              height: "80%",
              position: "relative",
              boxSizing: "border-box",
            }}
            className="event_operation"
          >
            <h5>
              Select an operation
              <br /> on Units
            </h5>
            <div className="label_container">
              <Average fill="black" className="metric_icon" />
              <span className="metric_label">Average</span>
              <Checkbox
                className="metric_options"
                onChange={(e) => handleMetricSelection(e, "average")}
                checked={checkboxGroup.average}
              ></Checkbox>
            </div>
            <div className="label_container">
              <Median fill="black" className="metric_icon" />
              <span className="metric_label">Median</span>
              <Checkbox
                className="metric_options"
                onChange={(e) => handleMetricSelection(e, "median")}
                checked={checkboxGroup.median}
              ></Checkbox>
            </div>
            <div className="label_container">
              <Sum fill="black" className="metric_icon" />
              <span className="metric_label">Sum</span>
              <Checkbox
                className="metric_options"
                onChange={(e) => handleMetricSelection(e, "sum")}
                checked={checkboxGroup.sum}
              ></Checkbox>
            </div>
          </div>
        </div>
        <Divider style={{ margin: "5px 0 0 0" }} />
        <div className="metric_buttons">
          <Button style={{ margin: "10px" }} onClick={() => closeFilter()}>
            Cancel
          </Button>
          <Button
            onClick={() => postMetricSelection(targetId)}
            style={{ margin: "10px" }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default metric;
