import React, { useState } from "react";
import displayPicture from "../../assests/dp.jpg";
import { Divider } from "antd";
import { DownOutlined, BellFilled, LogoutOutlined } from "@ant-design/icons";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";
import { logDOM } from "@testing-library/react";

const header = ({ parentCallback }) => {
  const [activeFlag, setActiveFlag] = useState("");

  let location = useLocation();

  const tabChange = (tab) => {
    setActiveFlag(tab);
    // parentCallback(tab);
  };

  useEffect(() => {
    let currentTab = location.pathname.substring(1);
    console.log(currentTab, "HEADER MOUNT CHANGE");
    setActiveFlag(currentTab.charAt(0).toUpperCase() + currentTab.slice(1));
    if (currentTab.length === 0) {
      setActiveFlag("Connect");
    }
  }, []);

  return (
    <div className="header">
      <div className="tabs">
        <div className="single_tab" onClick={() => tabChange("Connect")}>
          <Link to="/" className="header_link">
            Connect
          </Link>
          {activeFlag === "Connect" && <div className="activeBar"></div>}
        </div>
        <div className="single_tab" onClick={() => tabChange("Input")}>
          <Link to="/input" className="header_link">
            Input
          </Link>
          {activeFlag === "Input" && <div className="activeBar"></div>}
        </div>
        <div className="single_tab" onClick={() => tabChange("What")}>
          <Link to="/what" className="header_link">
            What
          </Link>
          {activeFlag === "What" && <div className="activeBar"></div>}
        </div>
        <div className="single_tab" onClick={() => tabChange("Why")}>
          <Link to="/why" className="header_link">
            Why
          </Link>
          {activeFlag === "Why" && <div className="activeBar"></div>}
        </div>
        <div className="single_tab" onClick={() => tabChange("WhatIf")}>
          <Link to="whatIf" className="header_link">
            {" "}
            What If
          </Link>
          {activeFlag === "WhatIf" && <div className="activeBar"></div>}
        </div>
      </div>
      <div className="spacer"></div>

      <div className="profile_section">
        <div>
          <h4 className="user_name">Cosmo Kramer</h4>
          <span className="profession">Analyst</span>
        </div>
        <div className="display_picture">
          <img src={displayPicture} />
        </div>
        <DownOutlined className="header_dropdown" />
        <Divider
          type="vertical"
          style={{
            color: "#a6acbe",
            height: "90%",
            width: "1px",
            backgroundColor: "#ebedf0",
          }}
        />
        <div className="action_icons">
          <BellFilled />
        </div>
        <div className="action_icons">
          <LogoutOutlined />
        </div>
        <Divider type="vertical" />
      </div>
    </div>
  );
};

export default header;
