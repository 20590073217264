import React, { useState, useEffect } from "react";
import { Space, Table, Tag, Tooltip } from "antd";

const summaryTable = ({ dataSource, tableData }) => {
  // console.log(dataSource);
  console.log(tableData);
  function numFormatter(num) {
    console.log(num);
    let negativeFlag = num < 0;
    num = num < 0 ? num * -1 : num;

    if (num < 999) {
      return negativeFlag
        ? "-" + Math.round(num * 100) / 100
        : Math.round(num * 100) / 100;
    }
    if (num > 999 && num < 1000000) {
      return negativeFlag
        ? "-" + (num / 1000).toFixed(1) + "K"
        : (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return negativeFlag
        ? "-" + (num / 1000000).toFixed(1) + "M"
        : (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return negativeFlag ? "-" + num : num; // if value < 1000, nothing to do
    }
  }
  const columns = [
    {
      title: "",
      key: "subpopulation",
      render: (record) => {
        return <div className="table_labels">{record.name}</div>;
      },
      fixed: "left",
      width: 150,
    },
    ...dataSource.actions.map((element) => {
      console.log(element);
      return {
        title: <div className="action_heading">{element}</div>,
        key: element,
        width: 80,
        fixed: element === "total" ? "right" : "",
        render: (record) => {
          return record[element].statisticallySignificant ? (
            <Tooltip
              title={
                <span>
                  Value: {`${" "} ${record[element]?.value.toFixed(2)}`} <br />{" "}
                  Count: {record[element]?.count}
                </span>
              }
            >
              <div className="summary_table_cell_true">
                {record[element]?.value !== null &&
                record[element]?.value !== undefined
                  ? numFormatter(record[element]?.value.toFixed(2))
                  : "None"}
                {record.name !== "total" && <span className="triangle"></span>}
                {record.name !== "total" && (
                  <span className="triangle_value">
                    {/* {console.log(record.name, "Name")} */}
                    {numFormatter(record[element]?.count)}
                  </span>
                )}
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              title={
                <span>
                  Value: {`${" "} ${record[element]?.value?.toFixed(2)}`} <br />{" "}
                  Count: {record[element]?.count}
                </span>
              }
            >
              <div
                className={
                  record?.name !== "total"
                    ? "summary_table_cell_false"
                    : "total_cell"
                }
              >
                {record[element]?.value !== null &&
                record[element]?.value !== undefined
                  ? numFormatter(record[element]?.value?.toFixed(2))
                  : "None"}
                {record.name !== "total" && <span className="triangle"></span>}
                {record.name !== "total" && (
                  <span className="triangle_value">
                    {/* {console.log(record.name, "Name")} */}
                    {numFormatter(record[element]?.count)}
                  </span>
                )}
              </div>
            </Tooltip>
          );
        },
        width: 120,
      };
    }),
    {
      title: <div className="table_labels">TOTAL</div>,
      key: "total",
      render: (record) => {
        // console.log(record);

        return (
          <div className="table_labels">
            {numFormatter(record?.total?.value)}
          </div>
        );

        // return <div className="table_labels">Total</div>;
      },
      width: 100,
      fixed: "right",
    },
  ];

  return (
    <div className="alignment_summary_table">
      <div className="summary_table_container">
        <div className="summary_table_horizontal">
          <h3 className="summary_table_heading">SUBPOPULATION</h3>
        </div>
        <div className="summary_table_vertical">
          <h3 className="summary_table_head">ACTION</h3>
          <Table
            columns={columns}
            dataSource={tableData}
            scroll={{ x: 450, y: 190 }}
            pagination={false}
            style={{
              width: "500px",
              height: "fit-content",
            }}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} className="table_labels">
                    Total
                  </Table.Summary.Cell>
                  {dataSource.actions.map((set, index) => {
                    console.log(
                      dataSource.data[dataSource.data.length - 1][set].value
                    );
                    return (
                      <Table.Summary.Cell
                        index={index + 1}
                        className="total_cell_summary"
                      >
                        {numFormatter(
                          dataSource.data[dataSource.data.length - 1][set].value
                        )}
                      </Table.Summary.Cell>
                    );
                  })}
                </Table.Summary.Row>
                <div className="extra_cell">Total</div>
              </Table.Summary>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default summaryTable;
