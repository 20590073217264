import React, { useEffect, useState } from "react";
import { colors } from "./colorScheme";
import {
  AreaChart,
  Area,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from "recharts";
import "../modules/whyBoard/why.css";
import { Divider } from "antd";
import Pulse from "react-reveal/Pulse";

const Areachart = ({ areaData, actions }) => {
  // const colors = ["rgb(127, 183, 126)", "#8884d8", "#EBCC2C"];
  const [mapArea, setMapArea] = useState([]);
  function numFormatter(num) {
    console.log(num);
    if (num < 999) {
      return Math.round(num * 100) / 100;
    }
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  }
  const xFormat = (data) => {
    console.log(data);
    if (data !== undefined && data !== null && data !== "") {
      console.log(data);
      return data?.toFixed(2) || "something";
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      console.log(
        active,
        payload,
        label,
        "AREA CHART LABELS FOR TOOTIP",
        payload[0].x_value
      );
      return (
        <div className="tooltip_labels">
          {actions.map((x, index) => {
            return (
              <p style={{ color: colors[index] }}>
                {`${x} `}: {` `} {numFormatter(payload[0].payload[`${x}`])}
              </p>
            );
          })}
          <Divider style={{ margin: "5px 0" }} />
          <div className="tooltip_x">
            <p>
              RANGE :{" "}
              {numFormatter(
                Number(payload[0].payload.labels.split(",")[0].substring(2))
              )}{" "}
              to
              {` `}
              {numFormatter(
                Number(
                  payload[0].payload.labels
                    .split(",")[1]
                    .substring(
                      0,
                      payload[0].payload.labels.split(",")[1].length - 1
                    )
                )
              )}{" "}
            </p>
          </div>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    let tempArea = [];
    console.log(areaData, actions);
  }, [areaData]);
  return (
    <ResponsiveContainer width="100%" height="90%">
      <AreaChart
        width={500}
        height={300}
        data={areaData}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          {/* <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#EBCC2C" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#EBCC2C" stopOpacity={0} />
        </linearGradient> */}
          {actions.map((x, index) => {
            return (
              <linearGradient id={x} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={colors[index]} stopOpacity={0.8} />
                <stop offset="95%" stopColor={colors[index]} stopOpacity={0} />
              </linearGradient>
            );
          })}
        </defs>
        <XAxis dataKey="x_value" tickFormatter={xFormat} />
        <YAxis />
        <CartesianGrid strokeDasharray="0 1" />
        <Tooltip content={<CustomTooltip />} />
        {/* <Area
        type="monotone"
        dataKey="action_1"
        stroke="#8884d8"
        fillOpacity={1}
        fill="url(#colorUv)"
      />
      <Area
        type="monotone"
        dataKey="action_2"
        stroke="#EBCC2C"
        fillOpacity={1}
        fill="url(#colorPv)"
      /> */}
        {actions.map((y, index) => {
          return (
            <Area
              type="monotone"
              dataKey={String(y)}
              stroke={colors[index]}
              fillOpacity={1}
              fill={`url(#${String(y)})`}
            />
          );
        })}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default Areachart;
