export const data = {
  event_name: "event_1",
  event_type: "compare_time_periods",
  event_summary_number: 10000,
  time_label: null,
  first_period: {
    period_name: "previous period",
    period_start_date: "2022-03-01T00:00:00.001Z",
    period_end_date: "2022-03-07T00:00:00.001Z",
    period_summary_number: 200232.93,
    time_operation: "average",
    min: 130232.93,
    max: 220232.93,
    average: 200232.93,
    median: 190232.93,
    sum: 19245032.93,
    rows: 10023,
  },
  second_period: {
    period_name: "recent period",
    period_start_date: "2022-03-08T00:00:00.001Z",
    period_end_date: "2022-03-14T00:00:00.001Z",
    period_summary_number: 210232.93,
    time_operation: "average",
    min: 140232.93,
    max: 230232.93,
    average: 210232.93,
    median: 200232.93,
    sum: 22245032.93,
    rows: 10023,
    summary_per_diff: 13.02,
  },
};

export const why_table = {
  subpopulations: ["subpopulation 1", "subpopulation 2", "subpopulation 3"],
  actions: ["treatment_1", "treatment_2", "treatment_3"],
  action_label: "Treatment",
  data: [
    {
      name: "subpopulation 1",
      treatment_1: {
        count: 523,
        value: 1.2,
        statisticallySignificant: true,
      },
      treatment_2: {
        count: 123,
        value: 1.2,
        statisticallySignificant: false,
      },
      total: {
        count: 646,
        value: 1.2,
      },
      treatment_3: {
        count: 123,
        value: 1.2,
        statisticallySignificant: false,
      },
    },
    {
      name: "subpopulation 2",
      treatment_1: {
        count: 23,
        value: 1.2,
        statisticallySignificant: false,
      },
      treatment_2: {
        count: 123,
        value: 1.6,
        statisticallySignificant: false,
      },
      treatment_3: {
        count: 123,
        value: 1.2,
        statisticallySignificant: false,
      },
      total: {
        count: 646,
        value: 1.2,
      },
    },

    {
      name: "subpopulation 3",
      treatment_1: {
        count: 523,
        value: 1.9,
        statisticallySignificant: true,
      },
      treatment_2: {
        count: 123,
        value: 1.3,
        statisticallySignificant: false,
      },
      treatment_3: {
        count: 123,
        value: 1.2,
        statisticallySignificant: false,
      },
      total: {
        count: 646,
        value: 1.2,
      },
    },

    {
      name: "subpopulation 4",
      treatment_1: {
        count: 533,
        value: 2.9,
        statisticallySignificant: true,
      },
      treatment_2: {
        count: 123,
        value: 1.3,
        statisticallySignificant: false,
      },
      treatment_3: {
        count: 123,
        value: 1.2,
        statisticallySignificant: true,
      },
      total: {
        count: 646,
        value: 1.2,
      },
    },

    {
      name: "Total",
      treatment_1: {
        count: 523,
        value: 1.9,
        statisticallySignificant: true,
      },
      treatment_2: {
        count: 123,
        value: 1.8,
        statisticallySignificant: false,
      },
      treatment_3: {
        count: 123,
        value: 1.2,
        statisticallySignificant: false,
      },
      total: {
        count: 1646,
        value: 1.7,
      },
    },
  ],
};

export const summaryTree = {
  max_depth: 3,
  nodes: [
    {
      id: "1",
      sourcePosition: "right",
      type: "input",
      data: { label: "stage = advanced" },
      position: { x: 0, y: 100 },
    },
    {
      id: "2",
      sourcePosition: "right",
      targetPosition: "left",
      type: "condition",
      data: { label: "age >= 30" },
      position: { x: 250, y: -50 },
    },
    {
      id: "3",
      type: "condition",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: "Some=some" },
      position: { x: 250, y: 250 },
    },
    {
      id: "4",
      type: "output",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: "subpopulation 2" },
      position: { x: 500, y: -100 },
    },
    {
      id: "5",
      label: "subpopulation 3",
      type: "output",
      sourcePosition: "right",
      targetPosition: "left",
      position: { x: 500, y: 50 },
      data: {
        label: "subpopulation 3",
      },
    },
    {
      id: "6",
      label: "subpopulation 4",
      type: "output",
      sourcePosition: "right",
      targetPosition: "left",
      position: { x: 500, y: 200 },
      data: {
        label: "subpopulation 4",
      },
    },
    {
      id: "7",
      label: "subpopulation 5",
      type: "output",
      sourcePosition: "right",
      targetPosition: "left",
      position: { x: 500, y: 350 },
      data: {
        label: "subpopulation 5",
      },
    },
  ],
  edges: [
    {
      id: "1-3",
      label: "Yes",
      source: "1",
      target: "3",
      animated: true,
    },
    {
      id: "1-2",
      label: "No",
      source: "1",
      target: "2",
      animated: true,
    },
    {
      id: "2-4",
      label: "Yes",
      source: "2",
      target: "4",
      animated: true,
    },
    {
      id: "2-5",
      label: "Yes",
      source: "2",
      target: "5",
      animated: true,
    },
    {
      id: "2-5",
      label: "Yes",
      source: "2",
      target: "5",
      animated: true,
    },
    {
      id: "3-6",
      label: "Yes",
      source: "3",
      target: "6",
      animated: true,
    },
    {
      id: "3-7",
      label: "Yes",
      source: "3",
      target: "7",
      animated: true,
    },
  ],
};

export const something = {
  histogram: {
    data: [
      {
        action_0: 24,
        action_1: 16,
        action_2: 14,
        labels: "[1.3271185666330207 ,1.4508367842847678)",
        x_value: 1.3889776754588943,
      },

      {
        action_0: 1,
        action_1: 43,
        action_2: 4,
        labels: "[1.574555001936515 ,1.6982732195882622)",
        x_value: 1.6364141107623886,
      },

      {
        action_0: 3,
        action_1: 4,
        action_2: 5,
        labels: "[1.8219914372400092 ,1.9457096548917563)",
        x_value: 1.8838505460658828,
      },

      {
        action_0: 40,
        action_1: 9,
        action_2: 8,
        labels: "[2.0694278725435034 ,2.193146090195251)",
        x_value: 2.131286981369377,
      },

      {
        action_0: 4,
        action_1: 5,
        action_2: 0,
        labels: "[2.316864307846998 ,2.440582525498745)",
        x_value: 2.3787234166728712,
      },

      {
        action_0: 0,
        action_1: 10,
        action_2: 0,
        labels: "[2.564300743150492 ,2.688018960802239)",
        x_value: 2.6261598519763654,
      },

      {
        action_0: 0,
        action_1: 9,
        action_2: 9,
        labels: "[2.8117371784539866 ,2.9354553961057333)",
        x_value: 2.87359628727986,
      },

      {
        action_0: 0,
        action_1: 13,
        action_2: 12,
        labels: "[3.0591736137574808 ,3.182891831409228)",
        x_value: 3.1210327225833545,
      },

      {
        action_0: 12,
        action_1: 23,
        action_2: 15,
        labels: "[3.306610049060975 ,3.4303282667127224)",
        x_value: 3.3684691578868486,
      },

      {
        action_0: 20,
        action_1: 13,
        action_2: 0,
        labels: "[3.554046484364469 ,3.6777647020162165)",
        x_value: 3.6159055931903428,
      },
    ],
    header: {
      data_keys: ["action_0", "action_1", "action_2"],
      recordCount: 19,
    },
  },
  labels: [
    {
      key: "action_0",
      label: "treatment =  treatment 1 ",
    },
    {
      key: "action_1",
      label: "treatment =  treatment 2 ",
    },
    {
      key: "action_2",
      label: "treatment =  treatment 0 ",
    },
  ],
  pie_chart: {
    data: [
      {
        count: 0,
        label: "action_0",
      },
      {
        count: 128,
        label: "action_1",
      },
      {
        count: 0,
        label: "action_2",
      },
    ],
    total: 128,
  },
  status: "success",
  time_series: {
    data: [
      {
        action_0: 1.79477171329939,
        action_1: 8.79477171329939,
        action_2: 6.79477171329939,
        time: "Sun, 22 Mar 2020 00:00:00 GMT",
      },
      {
        action_0: 36.79477171329939,
        action_1: 28.79477171329939,
        action_2: 6.79477171329939,
        time: "Sun, 29 Mar 2020 00:00:00 GMT",
      },
      {
        action_0: 16.79477171329939,
        action_1: 38.79477171329939,
        action_2: 26.79477171329939,
        time: "Sun, 12 Apr 2020 00:00:00 GMT",
      },
      {
        action_0: 26.79477171329939,
        action_1: 28.79477171329939,
        action_2: 26.79477171329939,
        time: "Sun, 19 Apr 2020 00:00:00 GMT",
      },
      {
        action_0: 27.79477171329939,
        action_1: 37.79477171329939,
        action_2: 4.79477171329939,
        time: "Sun, 26 Apr 2020 00:00:00 GMT",
      },
      {
        action_0: 27.79477171329939,
        action_1: 37.79477171329939,
        action_2: 4.79477171329939,
        time: "Sun, 10 May 2020 00:00:00 GMT",
      },
      {
        action_0: 27.79477171329939,
        action_1: 37.79477171329939,
        action_2: 4.79477171329939,
        time: "Sun, 17 May 2020 00:00:00 GMT",
      },
      {
        action_0: 27.79477171329939,
        action_1: 37.79477171329939,
        action_2: 4.79477171329939,
        time: "Sun, 24 May 2020 00:00:00 GMT",
      },
      {
        action_0: 27.79477171329939,
        action_1: 3.79477171329939,
        action_2: 4.79477171329939,
        time: "Sun, 31 May 2020 00:00:00 GMT",
      },
      {
        action_0: 37.79477171329939,
        action_1: 27.79477171329939,
        action_2: 4.79477171329939,
        time: "Sun, 07 Jun 2020 00:00:00 GMT",
      },

      {
        action_0: 27.79477171329939,
        action_1: 37.79477171329939,
        action_2: 4.79477171329939,
        time: "Sun, 21 Jun 2020 00:00:00 GMT",
      },
    ],
    header: {
      data_keys: ["action_0", "action_1", "action_2"],
      recordCount: 16,
    },
  },
};

export const event1 = {
  event_name: "Event_A",
  event_type: "compare_time_periods",
  event_summary_number: 10000,
  first_period: {
    period_name: "previous period",
    period_start_date: "2022-03-01T00:00:00.001Z",
    period_end_date: "2022-03-07T00:00:00.001Z",
    time_label: null,
    summary_number: 200232.93,
    min: 130232.93,
    max: 220232.93,
    average: 200232.93,
    median: 190232.93,
    sum: 19245032.93,
    rows: 10023,
  },
  second_period: {
    period_name: "recent period",
    period_start_date: "2022-03-08T00:00:00.001Z",
    period_end_date: "2022-03-14T00:00:00.001Z",
    time_label: null,
    summary_number: 210232.93,
    min: 140232.93,
    max: 230232.93,
    average: 210232.93,
    median: 200232.93,
    sum: 22245032.93,
    rows: 10023,
    diff: 13.02,
  },
};

export const event2 = {
  event_name: "Event_C",
  event_type: "compare_time_periods",
  event_summary_number: 22000,
  first_period: {
    period_name: "previous period",
    period_start_date: "2022-03-01T00:00:00.001Z",
    period_end_date: "2022-03-07T00:00:00.001Z",
    time_label: null,
    summary_number: 200232.93,
    min: 130232.93,
    max: 220232.93,
    average: 200232.93,
    median: 190232.93,
    sum: 19245032.93,
    rows: 10023,
  },
  second_period: {
    period_name: "recent period",
    period_start_date: "2022-03-08T00:00:00.001Z",
    period_end_date: "2022-03-14T00:00:00.001Z",
    time_label: null,
    summary_number: 210232.93,
    min: 140232.93,
    max: 230232.93,
    average: 178232.93,
    median: 200232.93,
    sum: 22245032.93,
    rows: 10023,
    diff: -11.02,
  },
};
