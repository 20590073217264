import React from "react";
import connectPlaceholder from "../../assests/connect.svg";
import "./connect.css";

const connect = () => {
  return (
    <div className="connect_tab">
      <img
        src={connectPlaceholder}
        alt="placeholder"
        className="image_connect"
      ></img>
    </div>
  );
};

export default connect;
