import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import SideNavigation from "./components/sideNavigation/sideNavigation";
import Header from "./components/header/header";
import Dashboard from "./components/dashboard/dashboard";
import Connect from "./modules/connectBoard/connect";
import Input from "./modules/inputTabBoard/inputTab";
import When from "./modules/whenBoard/whenBoard";
import Why from "./modules/whyBoard/whyBoard";
import WhatIf from "./modules/whatIfBoard/whatIf";
import "antd/dist/antd.css";
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";
import { StrictMode } from "react";
import ErrorBoundary from "./modules/ErrorBoundary";

function App() {
  const [tabChange, setTab] = useState("Input");

  const parentCallback = (tab) => {
    setTab(tab);
    console.log(tab, "TAB_CHANGE");
  };
  return (
    <ErrorBoundary>
      <div className="App">
        <div className="layout_app">
          <BrowserRouter>
            <SideNavigation />
            <div className="dashboard_layout">
              <Header className="header" parentCallback={parentCallback} />

              {/* <Dashboard className="dashboard" tabChange={tabChange} /> */}
              <div className="dashboard">
                <div className="boards">
                  <Routes>
                    <Route path="/input" element={<Input />} />

                    <Route path="/what" element={<When />} />

                    <Route path="/why" element={<Why />} />

                    <Route path="/whatIf" element={<WhatIf />} />
                    <Route path="/connect" element={<Connect />} />

                    <Route path="/" element={<Navigate to="/connect" />} />
                  </Routes>
                </div>
              </div>
            </div>
          </BrowserRouter>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default App;
