import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  Select,
  Button,
  Tooltip,
  Modal,
  Checkbox,
  Divider,
  message,
  Progress,
  notification,
  Radio,
  Space,
  Spin,
} from "antd";
import "./why.css";
import "../../components/buttons/buttons.css";
import { ReactComponent as Run } from "../../assests/run.svg";
import placeholder from "../../assests/whatPlaceholder.PNG";
import EventCard from "../whenBoard/eventCard";
import SummaryTable from "./summaryTable";
import { data, why_table, summaryTree } from "./dummy";
import SummaryTree from "./summaryTree";
import { EyeOutlined, ApiFilled } from "@ant-design/icons";
import "../../components/buttons/buttons.css";
import Divepie from "../../components/divePie.js";
import Areachart from "../../components/areaChart";
import Comparetimeseries from "../../components/compareTime";
import axios from "axios";
import { ReactComponent as Running } from "../../assests/running.svg";
import { ReactComponent as Gear } from "../../assests/gear.svg";
import { Message } from "../../components/messages/message";
import { colors } from "../../components/colorScheme";

const API_URL = process.env.REACT_APP_API_URL;
const whyBoard = () => {
  const { Option } = Select;
  const [diveParams, setDiveParams] = useState("");
  const [eventChosen, setEventChosen] = useState("");
  const [noEventFlag, setEventFlag] = useState(true);
  const [previewFlag, setPreviewFlag] = useState(false);
  const [diveFlag, setDiveFlag] = useState(true);
  const [editDiveFlag, setEditDive] = useState(false);
  const [subPopFlag, setSubPopFlag] = useState(false);
  const [subActionFlag, setActionFlag] = useState(false);
  const [diveData, setDiveData] = useState(false);
  const [eventList, setEventList] = useState();
  const [progress, setProgress] = useState();
  const [running, setRunning] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [apiMessages, setMessages] = useState("Processing");
  const [eventSummary, setEventSummary] = useState([]);
  const [cardObject, setCardObject] = useState({});
  const [summaryTable, setSummaryTable] = useState();
  const [tree, setTree] = useState();
  const [subPoplist, setSubpopList] = useState([]);
  const [subActionlist, setActionList] = useState([]);
  const [pieData, setPie] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [mapKey, setMapKey] = useState([]);
  const [diveLoading, setDiveLoading] = useState(false);
  const [legendData, setLegendData] = useState([]);
  const plainOptions = ["Subpopulation", "Action"];
  const [runAllowed, setRunAllow] = useState(false);
  const [horizontalLegend, setLegendOrientation] = useState(true);
  const [polling, setPolling] = useState(false);
  const [empty, setEmpty] = useState(true);
  const [tabData, setTabData] = useState([]);

  const getLevelPositions = (level) => {
    let posArray = [];
    let start = -50 * level;
    if (level === 0) {
      return [{ x: 0, y: 100 }];
    }
    if (level === 1) {
      return [
        { x: 250, y: 0 },
        { x: 250, y: 250 },
      ];
    }
    for (let j = 0; j < Math.pow(2, level); j++) {
      posArray.push({ x: level * 250, y: start + j * 150 });
    }
    return posArray;
  };

  const getMappableTree = (rawData) => {
    let position = [];
    let levelNodes = [];
    let numArray = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
    ];
    for (let i = 0; i <= rawData.max_depth; i++) {
      position.push(...getLevelPositions(i));
    }
    console.log(position, "Position Object");
    let edges = [];
    let nodes = [];
    rawData.edges.map((obj) => {
      edges.push({
        id: String(obj.id),
        label: String(obj.label),
        source: String(obj.source),
        target: String(obj.target),
        animated: true,
      });
    });
    rawData.nodes.map((obj) => {
      console.log(
        String(
          obj.label.length < 15
            ? " ".repeat(3) + obj.label + " ".repeat(3)
            : obj.label
        )
      );
      obj.id === 0
        ? nodes.push({
            id: String(obj.id),
            sourcePosition: "right",
            type: "input",
            data: { label: obj.label },
            position: { x: 0, y: 100 },
          })
        : nodes.push({
            id: String(obj.id),
            type: obj.type === "condition" ? obj.type : "output",
            sourcePosition: "right",
            targetPosition: "left",
            data: {
              label: String(
                obj.label.length < 15
                  ? " ".repeat(3) + obj.label + " ".repeat(3)
                  : obj.label
              ),
            },
            position: position[obj.id],
          });
    });
    return { edges, nodes };
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
    // setEventFlag(false);
    setEventChosen(value);
    getWhyResult(value);
    setRunning(true);
    setRunAllow(false);
  };

  const onRadioChange = (e, mode) => {
    console.log("radio checked", e.target.value.replace(/\s/g, ""), mode);
    let value =
      mode === "action"
        ? Object.keys(subActionlist).find(
            (key) => subActionlist[key] === e.target.value
          )
        : Object.keys(subPoplist).find(
            (key) => subPoplist[key] === e.target.value.replace(/\s/g, "")
          );
    console.log(value);
    mode === "action"
      ? setDiveParams(`action=${value}`)
      : setDiveParams(`subpopulation=${value}`);
  };

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: type.toUpperCase(),
      description: description,
    });
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const goToDive = () => {
    setTimeout(() => {
      window.scrollTo({ top: 1300, behavior: "smooth" });
    }, 500);
  };

  const getWhyResult = async (name) => {
    setDiveData(false);
    setProgress(0);
    var getConfig = {
      method: "get",
      url: `http://3.216.238.11/why_analysis/get/${name}/0`,
      headers: {
        key: "Y6KBUDa97drMc6r5oDlG4aHSoVYulRJb",
      },
    };
    axios(getConfig)
      .then(function(response) {
        console.log(JSON.stringify(response.data));
        console.log(response.status);
        if (response.data.status === "success") {
          setProgress(100);
          setRunAllow(false);
          openNotificationWithIcon(
            "success",
            "Successfully got the get results"
          );
          setMessages("Completed");
          setEventFlag(false);
          setSummaryTable(response.data.summary_table);
          let tab = [];
          for (
            let i = 0;
            i < response.data.summary_table.data.length - 1;
            i++
          ) {
            tab.push(response.data.summary_table.data[i]);
          }
          setTabData(tab);
          setTree(response.data.tree);
          setSubpopList(response.data.subpop_list);
          setActionList(response.data.actions_list);
          setRunning(false);
        }
        if (response.data.status === "NOT STARTED") {
          setRunning(false);
          setRunAllow(true);
          openNotificationWithIcon(
            "info",
            "Run has not been performed, please run the operation for the selected event"
          );
          setEventFlag(true);
        }
        if (response.data.status === "NOT FINISHED") {
          if (!polling) {
            startPolling(response.headers.location);
          }
          setMessages("Fetching");
        }
      })
      .catch(function(error) {
        console.log(error);
        openNotificationWithIcon("error", "Failed to fetch the get results");
      });
  };

  const getEventList = async () => {
    var axios = require("axios");

    var config = {
      method: "get",
      url: `${API_URL}/events_mgmt/events_summary?project_id=0`,
      headers: {
        key: "Y6KBUDa97drMc6r5oDlG4aHSoVYulRJb",
      },
    };

    axios(config)
      .then(function(response) {
        console.log(response.data);
        // message.success("Fetched the list of events");
        openNotificationWithIcon("success", "Fetched the list of events");
        let tempArray = response.data.events.map((x) => {
          return {
            name: x.event_name,
          };
        });
        setEventList(tempArray);
        setEventSummary(response.data.events);
      })
      .catch(function(error) {
        openNotificationWithIcon(
          "error",
          "Failed to fetch the list of events "
        );
        console.log(error);
      });
  };

  const runSimulator = async (chosen) => {
    setEventFlag(true);
    console.log(eventChosen);
    var data = JSON.stringify({
      event_name: chosen,
      project_id: 0,
    });

    var configRun = {
      method: "post",
      url: `${API_URL}/why_analysis/run`,
      headers: {
        key: "Y6KBUDa97drMc6r5oDlG4aHSoVYulRJb",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(configRun)
      .then(function(response) {
        console.log(JSON.stringify(response.data));
        console.log(response.status);
        console.log(response.headers);
        if (response.status === 202) {
          openNotificationWithIcon("success", "Run request accepted");
          startPolling(response.headers.location);
          setMessages("Fetching");
        }
        if (response.status === 200) {
          setProgress(100);
          setMessages("Preparing");
          getRunningResult(response.headers.location);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const startPolling = async (location) => {
    setPolling(true);
    console.log(location);
    var configPolling = {
      method: "get",
      url: `${API_URL}/${location}`,
      headers: {
        key: "Y6KBUDa97drMc6r5oDlG4aHSoVYulRJb",
        "Content-Type": "application/json",
      },
    };
    try {
      axios(configPolling)
        .then(function(response) {
          console.log(response.data);
          console.log(response.status);
          console.log(response.headers);
          if (response.data.state !== "SUCCESS") {
            startPolling(location);
            if (response.data.hasOwnProperty("progress")) {
              console.log(response.data.progress);
              setProcessing(true);
              setProgress(response.data.progress * 100);
            }
          } else {
            setProgress(100);
            getRunningResult(response.data.url);
            setMessages("Preparing");
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    } catch (error) {
      console.log("Error Found");
    }
  };

  const getRunningResult = async (location) => {
    setEventFlag(true);
    var getResultConfig = {
      method: "get",
      url: `${API_URL}/${location}`,
      headers: {
        key: "Y6KBUDa97drMc6r5oDlG4aHSoVYulRJb",
        "Content-Type": "application/json",
      },
    };

    axios(getResultConfig)
      .then(function(response) {
        setRunAllow(false);
        console.log(response.data);
        // message.success("Fetched the list of events");
        setTimeout(() => {
          setRunning(false);
        }, 1000);
        openNotificationWithIcon("success", "Run operation complete !");
        setMessages("Completed");
        setEventFlag(false);
        setSummaryTable(response.data.summary_table);
        setTree(response.data.tree);
        setSubpopList(response.data.subpop_list);
        setActionList(response.data.actions_list);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const postDive = async () => {
    document.getElementById("scrollHere").scrollIntoView();
    setDiveLoading(true);
    var diveConfig = {
      method: "get",
      url: `${API_URL}/why_analysis/deep_dive/${eventChosen}/0?${diveParams}`,
      headers: {
        key: "Y6KBUDa97drMc6r5oDlG4aHSoVYulRJb",
      },
    };
    axios(diveConfig)
      .then(function(response) {
        console.log(response.data);
        openNotificationWithIcon("success", "Dive operation completed");
        let paresedData = response.data;
        console.log("PARSED_DATA", paresedData.time_series.header.data_keys);
        setPie(paresedData.pie_chart.data);
        setAreaData(paresedData.histogram.data);
        setLineData(paresedData.time_series.data);
        setMapKey(paresedData.time_series.header.data_keys);
        setLegendData(paresedData.labels);
        setDiveData(true);
        setDiveLoading(false);
        goToDive();
      })
      .catch(function(error) {
        console.log(error);
        openNotificationWithIcon("error", "Dive operation completed");
      });
  };

  useEffect(() => {
    getEventList();
  }, []);

  useEffect(() => {
    let obj;
    eventSummary.map((x) => {
      if (x.event_name === eventChosen) {
        obj = x;
      }
    });
    setCardObject(obj);
    console.log("Card Object", obj);
  }, [eventChosen]);

  useEffect(() => {
    console.log(tree);
    if (tree !== undefined) {
      getMappableTree(tree);
    }
  }, [tree]);

  useEffect(() => {
    console.log(
      "CHANGED",
      mapKey,
      "PIE_DATA",
      pieData,
      "AREA_DATA",
      areaData,
      "LINE_DATA",
      lineData
    );
    pieData.map((x) => {
      if (x.count !== 0) {
        setEmpty(false);
      }
    });
  }, [pieData, lineData, mapKey, areaData]);

  //  CUSTOM_HOOK_CHECKING_SIZE
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  const [width, height] = useWindowSize();

  useEffect(() => {
    console.log(width, height);
    if (width < 1300) {
      setLegendOrientation(false);
    } else {
      setLegendOrientation(true);
    }
  }, [width, height]);

  return (
    <div className="why_board">
      <div className="why_controls">
        <div className="control_select">
          {" "}
          <h3>Event List</h3>
          <Select
            showSearch
            placeholder="Select a event"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            loading={eventList === undefined}
            style={{ width: "130px" }}
            id="search_event"
          >
            {eventList !== undefined
              ? eventList.map((x) => {
                  return <Option value={x.name}>{x.name}</Option>;
                })
              : ""}
          </Select>
        </div>
        <div>
          <button
            className="profeci_event_button"
            onClick={() => {
              runSimulator(eventChosen);
              setRunning(true);
              setDiveData(false);
              // fetchData();
            }}
            disabled={!runAllowed}
          >
            <Run
              className="run_icon"
              fill={!runAllowed ? "#BAB1E9" : "white"}
            />
            <span className="run_title">RUN</span>
          </button>
          {!running && runAllowed && (
            <span className="control_message">Never Run Before</span>
          )}{" "}
        </div>
      </div>
      {noEventFlag && !running ? (
        <div className="why_placeholder">
          <h1>Heads up</h1>
          <p>
            You haven’t selected any event yet. <br />
            Please make a selection!
          </p>
          <img
            src={placeholder}
            alt="Placeholder"
            style={{ height: "200px" }}
          />
        </div>
      ) : running ? (
        <div className="loading_run">
          <Spin />
        </div>
      ) : null}
      {running && (
        <div className="running_process">
          <Gear className="running_animation" />
          <span className="apiMessages">{apiMessages}</span>
          <Progress percent={progress} size="small" className="progress_bar" />
        </div>
      )}
      {!noEventFlag && !running && (
        <div style={{ width: "fit-content" }} className="why_event_summary">
          {cardObject !== undefined && cardObject !== null && (
            <EventCard cardObject={cardObject} />
          )}{" "}
        </div>
      )}
      {!noEventFlag && !running && (
        <div className="why_summary">
          <div className="why_summary_header">EVENT SUMMARY</div>
          <div className="summary_board">
            <div className="summary_tree">
              <div className="summary_header_tree">
                {" "}
                <h2>Subpopulation tree</h2>
                <span
                  className="preview_container"
                  onClick={() => setPreviewFlag(true)}
                >
                  <Tooltip title="Preview" style={{ zIndex: 1000 }}>
                    <EyeOutlined className="preview_icon" />
                  </Tooltip>
                </span>
              </div>

              <Modal
                onCancel={() => setPreviewFlag(false)}
                onOk={() => setPreviewFlag(false)}
                width="80%"
                visible={previewFlag}
              >
                {tree !== undefined && (
                  <SummaryTree
                    dataSource={getMappableTree(tree)}
                    mode="preview"
                  />
                )}
              </Modal>

              {tree !== undefined && (
                <SummaryTree dataSource={getMappableTree(tree)} mode="normal" />
              )}
            </div>
            <div className="summary_table">
              <h2>Overview table</h2>
              {summaryTable !== undefined && (
                <SummaryTable dataSource={summaryTable} tableData={tabData} />
              )}{" "}
            </div>
          </div>
        </div>
      )}
      {diveFlag && !noEventFlag && (
        <div className="why_summary">
          <div className="why_deep_dive">DEEP DIVE</div>
          <div className="why_deep_inner">
            <button
              className="profeci_event_button"
              onClick={() => setEditDive(true)}
            >
              New Dive
            </button>
          </div>
          <Spin
            tip="LOADING"
            spinning={diveLoading}
            className="dive_loading"
            id="scrollHere"
          />

          {diveData && (
            <div className="dive_container">
              <h2 style={{ marginBottom: "25px", paddingLeft: "8px" }}>
                Subpopulation dive for Subpopulation 2
                <span className="dive_tag">Tag 2</span>
              </h2>
              {!horizontalLegend && !empty && (
                <div className="legend_horizontal">
                  {" "}
                  {legendData.map((y, index) => {
                    return (
                      <>
                        <div className="legend_sub">
                          <p className="legend_label">
                            <p>{y.key.toUpperCase()}</p>
                            <div
                              className="legend_color"
                              style={{
                                backgroundColor: colors[index],
                                border: `solid 2px ${colors[index]}`,
                              }}
                            ></div>
                            <span style={{ color: colors[index] }}>
                              {y.label.toUpperCase()}
                            </span>
                          </p>
                        </div>
                      </>
                    );
                  })}
                </div>
              )}
              <div className="container_dive">
                {!empty ? (
                  <div className="dive_action_sub">
                    <div className="dive_pie">
                      <h3
                        style={{
                          fontWeight: "700",
                          textTransform: "uppercase",
                          marginBottom: "20px",
                        }}
                      >
                        Count overview
                      </h3>
                      <div className="chart_containers">
                        <Divepie data={pieData} />
                      </div>
                    </div>
                    <div className="dive_distribution">
                      <h3
                        style={{
                          fontWeight: "700",
                          textTransform: "uppercase",
                          marginBottom: "20px",
                        }}
                      >
                        Distributions overview
                      </h3>
                      <div className="chart_containers">
                        <Areachart
                          areaData={areaData}
                          actions={mapKey}
                          className="dive_area_chart"
                        />
                      </div>
                    </div>
                    <div className="dive_time_series">
                      <h3
                        style={{
                          fontWeight: "700",
                          textTransform: "uppercase",
                          marginBottom: "20px",
                        }}
                      >
                        Time series overview
                      </h3>
                      <div className="chart_containers">
                        <Comparetimeseries
                          lineData={lineData}
                          actions={mapKey}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="empty_response">
                    <ApiFilled
                      style={{
                        color: "#6d5bd0",
                        fontSize: "20px",
                        marginRight: "10px",
                      }}
                    />
                    The selected dive response is empty.
                  </div>
                )}
                {horizontalLegend && !empty && (
                  <div className="dive_legend">
                    {legendData.map((y, index) => {
                      return (
                        <>
                          <div className="legend_sub">
                            <p className="legend_label">
                              <p>{y.key.toUpperCase()}</p>
                              <div
                                className="legend_color"
                                style={{
                                  backgroundColor: colors[index],
                                  border: `solid 2px ${colors[index]}`,
                                }}
                              ></div>
                              <span style={{ color: colors[index] }}>
                                {y.label.toUpperCase()}
                              </span>
                            </p>
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}
          <Modal
            title="EDIT DIVE"
            visible={editDiveFlag}
            onOk={() => {
              setEditDive(false);
              setDiveData(false);
              postDive();
              goToDive();
            }}
            onCancel={() => setEditDive(false)}
            width="fit-content"
          >
            <div className="edit_dive">
              <div className="edit_options">
                <div className="checkboxes_options">
                  <p className="modal_headings">Select dive type</p>
                  <Checkbox style={{ display: "none" }}></Checkbox>
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSubPopFlag(true);
                        setActionFlag(false);
                      }
                    }}
                    checked={subPopFlag}
                  >
                    SUBPOPULATION
                  </Checkbox>

                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSubPopFlag(false);
                        setActionFlag(true);
                      }
                    }}
                    checked={subActionFlag}
                  >
                    ACTION
                  </Checkbox>
                </div>
              </div>
              <div className="edit_sub_options">
                <Checkbox style={{ display: "none" }}></Checkbox>
                {subPopFlag && (
                  <div className="checkboxes_options">
                    <p className="modal_headings">Select Subpopulation type</p>
                    <div className="radio_group">
                      <Radio.Group onChange={(e) => onRadioChange(e, "subPop")}>
                        <Space direction="vertical">
                          {Object.values(subPoplist).map((x) => {
                            return <Radio value={x}>{x}</Radio>;
                          })}
                        </Space>
                      </Radio.Group>
                    </div>
                  </div>
                )}

                {subActionFlag && (
                  <div className="checkboxes_options">
                    <p className="modal_headings">Select Action type</p>
                    <div className="radio_group">
                      <Radio.Group onChange={(e) => onRadioChange(e, "action")}>
                        <Space direction="vertical">
                          {Object.values(subActionlist).map((x) => {
                            return <Radio value={x}>{x}</Radio>;
                          })}
                        </Space>
                      </Radio.Group>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default whyBoard;
