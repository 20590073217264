import React from "react";
import { FolderFilled, SettingFilled } from "@ant-design/icons";
const sideNavigation = () => {
  return (
    <div className="side_navigation">
      <div className="logo">
        <p>P</p>
      </div>
      <div className="utility_icon">
        <div className="icon_container">
          <FolderFilled
            style={{
              fontSize: "28px",
              color: "white",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          />
        </div>
      </div>
      <div className="utility_icon">
        <div className="icon_container">
          <SettingFilled style={{ fontSize: "28px", color: "white" }} />
        </div>
      </div>
      <div className="utility_icon"></div>
    </div>
  );
};

export default sideNavigation;
