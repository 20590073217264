import React from "react";
import error from "../assests/error.svg";
import { useNavigate } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.handleRoute = this.handleRoute.bind(this);
  }

  handleRoute() {
    window.location.reload();
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log(error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error_boundary">
          <div className="image_container">
            <img src={error} alt="error"></img>
            <div className="error_message">
              <h1>ERROR 404 !</h1>
              <p>
                We are sorry for the inconvenience.It looks you are trying to
                access a page that either has been deleted or never been
                existed.
              </p>
              <button
                className="profeci_event_button"
                onClick={() => {
                  this.handleRoute();
                }}
              >
                {" "}
                RELOAD
              </button>
            </div>
          </div>
        </div>
      );
    }
    console.log(this.state, "ERROR_BOUNDARY");
    return this.props.children;
  }
}

export default ErrorBoundary;
