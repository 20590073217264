import React, { useState, useEffect } from "react";
import { Input, Checkbox, Divider, Modal, DatePicker, message } from "antd";
import "./what.css";
import { ReactComponent as Average } from "../../assests/average.svg";
import { ReactComponent as Median } from "../../assests/median.svg";
import { ReactComponent as Max } from "../../assests/maxBlack.svg";
import { ReactComponent as Min } from "../../assests/minBlack.svg";
import { ReactComponent as Sum } from "../../assests/sum.svg";

const operations = ({ parentCallback, opChosen }) => {
  const [timeAgg, setTimeAgg] = useState([]);
  const [checkboxGroup, setOptions] = useState({
    average: opChosen === "average",
    median: opChosen === "median",
    sum: opChosen === "sum",
    min: opChosen === "min",
    max: opChosen === "max",
  });
  const handleOperationSelection = (e, value) => {
    if (e.target.checked) {
      setTimeAgg(value);
      setOptions({
        average: value === "average",
        median: value === "median",
        sum: value === "sum",
        min: value === "min",
        max: value === "max",
      });
      parentCallback(value);
    }
  };
  return (
    <div>
      {" "}
      <div className="event_operation" style={{ position: "relative" }}>
        <div className="range_options">
          <div className="label_container">
            <Average className="metric_icon" />
            <span className="metric_label">Average</span>
            <Checkbox
              className="metric_options"
              onChange={(e) => {
                handleOperationSelection(e, "average");
              }}
              checked={checkboxGroup.average}
            ></Checkbox>
          </div>
          <div className="label_container">
            <Median className="metric_icon" />
            <span className="metric_label">Median</span>
            <Checkbox
              className="metric_options"
              onChange={(e) => {
                handleOperationSelection(e, "median");
              }}
              checked={checkboxGroup.median}
            ></Checkbox>
          </div>
          <div className="label_container">
            <Sum className="metric_icon" />
            <span className="metric_label">Sum</span>
            <Checkbox
              className="metric_options"
              onChange={(e) => {
                handleOperationSelection(e, "sum");
              }}
              checked={checkboxGroup.sum}
            ></Checkbox>
          </div>
          <div className="label_container">
            <Min className="metric_icon" />
            <span className="metric_label">Min</span>
            <Checkbox
              className="metric_options"
              onChange={(e) => {
                handleOperationSelection(e, "min");
              }}
              checked={checkboxGroup.min}
            ></Checkbox>
          </div>
          <div className="label_container">
            <Max className="metric_icon" />
            <span className="metric_label">Max</span>
            <Checkbox
              className="metric_options"
              onChange={(e) => {
                handleOperationSelection(e, "max");
              }}
              checked={checkboxGroup.max}
            ></Checkbox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default operations;
