import React, { useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import { useState } from "react";
import { colors } from "./colorScheme";

const Comparetimeseries = ({ lineData, actions }) => {
  const [chartData, setChartData] = useState([]);
  function numFormatter(num) {
    console.log(num);
    if (num < 999) {
      return Math.round(num * 100) / 100;
    }
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      console.log(active, payload, label, "LINE CHART LABELS FOR TOOTIP");
      return (
        <div className="tooltip_labels">
          {" "}
          {actions.map((x, index) => {
            return (
              <p style={{ color: colors[index] }}>
                {`${x} `}: {` `} {numFormatter(payload[0].payload[`${x}`])}
              </p>
            );
          })}
        </div>
      );
    }

    return null;
  };
  useEffect(() => {
    console.log(lineData, actions, "FROM TIME SERIES CHART");
    lineData.map((x) => {
      x["time"] = moment(x.time).format("MMMM DD, YYYY");
    });
    console.log(lineData, actions, "FROM TIME SERIES CHART");
    setChartData(lineData);
    console.log(lineData, actions, "Check Post");
  }, []);

  return (
    <ResponsiveContainer width="100%" height="90%">
      <LineChart
        width={500}
        height={300}
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid  /> */}
        <XAxis dataKey="time" />
        <YAxis />
        {actions.map((x, index) => {
          return (
            <Line
              type="monotone"
              dataKey={String(x)}
              stroke={colors[index]}
              activeDot={{ r: 8 }}
              strokeWidth={2}
            />
          );
        })}
        <Tooltip content={<CustomTooltip />} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Comparetimeseries;
