import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

import { EditFilled } from "@ant-design/icons";

import {
  Input,
  Checkbox,
  Divider,
  Modal,
  DatePicker,
  message,
  Select,
} from "antd";
import "../../components/buttons/buttons.css";
import Operations from "./operations";
import { ReactComponent as Average } from "../../assests/average.svg";
import { ReactComponent as Median } from "../../assests/median.svg";
import { ReactComponent as Max } from "../../assests/maxBlack.svg";
import { ReactComponent as Min } from "../../assests/minBlack.svg";
import { ReactComponent as Sum } from "../../assests/sum.svg";
import customFilter from "./customFilter";

const API_URL = process.env.REACT_APP_API_URL;

const { RangePicker } = DatePicker;
const eventFilter = ({
  closeFilter,
  comparePeriods,
  currentResponse,
  postData,
  updatePayload,
  start,
  end,
  setLoaders,
  mode,
}) => {
  const { Option } = Select;
  const eventChosen = localStorage.getItem("eventFilter");
  console.log(currentResponse, postData);
  const [firstStart, setFirstStart] = useState();
  const [firstEnd, setFirstEnd] = useState();
  const [secondStart, setSecondStart] = useState();
  const [secondEnd, setSecondEnd] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [genralModal, setGeneralVisible] = useState(false);
  const [response, setResponse] = useState([]);
  const [timeAgg, setTimeAgg] = useState();
  const [anamolyModal, setAnamolyVisible] = useState(false);
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("");
  const [anomalyOperation, setAnomalyOperation] = useState("difference");
  const [checkboxGroup, setOptions] = useState({
    average: false,
    median: false,
    sum: false,
    min: false,
    max: false,
  });
  const [mapData, setEventData] = useState({});

  useEffect(() => {
    console.log(mapData, "MAP_DATA");
    if (mapData.time_aggregation_anomaly_operation !== undefined) {
      mapData.time_aggregation_anomaly_operation.split(" ")[2] === "-"
        ? setAnomalyOperation("difference")
        : setAnomalyOperation("ratio");
    }
  }, [mapData]);

  const handleCompare = (e) => {
    if (e.target.checked) {
      // comparePeriods();
      setIsVisible(true);
    }
  };
  const handleGeneral = (e) => {
    if (e.target.checked) {
      setGeneralVisible(true);
    }
  };
  const handleAnamoly = (e) => {
    if (e.target.checked) {
      setAnamolyVisible(true);
    }
  };
  const onChange = (value, dateString) => {
    console.log("Formatted Selected Time: ", dateString);
    setFirstStart(dateString[0]);
    setFirstEnd(dateString[1]);
  };

  const onChangeSecond = (value, dateString) => {
    console.log(
      "Selected Time: ",
      value[0].toISOString(),
      value[1].toISOString()
    );
    console.log("Formatted Selected Time: ", dateString[0].replace(/\//g, "-"));
    setSecondStart(dateString[0]);
    setSecondEnd(dateString[1]);
  };

  const handleOperationSelection = (e, value) => {
    if (e.target.checked) {
      setTimeAgg(value);
      setOptions({
        average: value === "average",
        median: value === "median",
        sum: value === "sum",
        min: value === "min",
        max: value === "max",
      });
    }
  };

  // Side Effects
  const postEventSelection = async (type) => {
    closeFilter();
    console.log(type);
    console.log(postData);
    let dataMet = JSON.parse(localStorage.getItem("metricFilter"));
    let filter = JSON.parse(localStorage.getItem("customFilter"));
    console.log({ ...dataMet, filter });

    if (filter.length !== 0) {
      filter = filter.map((data) => {
        if (data !== null) {
          if (data.type === "numeric") {
            return {
              column: data.value,
              filter_type: "numeric",

              upper_limit:
                data.equ === "lesserthanqual" || data.equ === "equals"
                  ? data.num
                  : null,
              lower_limit:
                data.equ === "greaterthanequal" || data.equ === "equals"
                  ? data.num
                  : null,
            };
          }
          if (data.type === "categorical") {
            return {
              column: data.value,
              filter_type: "categorical",
              values: data.sub,
            };
          }
        }
      });
    }

    let previousPayload = { ...dataMet, filter };

    try {
      let data = {};
      setLoaders(true);
      if (type === "COMPARE_TIME_PERIOD") {
        data = {
          event_type: "compare_time_periods",
          event_first_period_start:
            firstStart || mapData.event_first_period_start,
          event_first_period_end: firstEnd || mapData.event_first_period_end,
          event_second_period_start:
            secondStart || mapData.event_second_period_start,
          event_second_period_end: secondEnd || mapData.event_second_period_end,
          time_aggregation: timeAgg,
        };
      }
      if (type === "GENERAL_PERFORMANCE") {
        data = {
          event_type: "general_performance",
          event_first_period_end: firstEnd || mapData.event_first_period_end,
          event_first_period_start:
            firstStart || mapData.event_first_period_start,
          time_aggregation: timeAgg || mapData.time_aggregation,
        };
      }

      if (type === "ANOMALY") {
        data = {
          event_type: "anomaly_analysis",
          event_first_period_end: firstEnd || mapData.event_first_period_end,
          event_first_period_start:
            firstStart || mapData.event_first_period_start,
          time_aggregation_anomaly_one:
            first || mapData.time_aggregation_anomaly_one,
          time_aggregation_anomaly_two:
            second || mapData.time_aggregation_anomaly_two,
          time_aggregation_anomaly_operation:
            anomalyOperation ||
            mapData.time_aggregation_anomaly_operation.split(" ")[2],
        };
      }

      let event_config = {
        method: "post",
        url: `${API_URL}/events_mgmt/event_temp_view`,
        headers: {
          key: "Y6KBUDa97drMc6r5oDlG4aHSoVYulRJb",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ ...data, ...previousPayload }),
      };

      axios(event_config)
        .then(function(response) {
          console.log(response.data);
          message.success("Successfully applied event filter");
          setResponse(response.data);
          updatePayload(data);
          // parentCallback(response.data);
          comparePeriods(response.data);
          localStorage.setItem("eventFilter", JSON.stringify(data));
          setLoaders(false);
        })
        .catch(function(error) {
          console.log(error);
          setIsVisible(false);
        });
    } catch (e) {
      message.error("Failed to apply event filter");
      console.log("err...", e);
    }
  };

  useEffect(() => {
    console.log(response.data);
  }, [response]);

  const [eventNameChosen, setChosenEvent] = useState();

  useEffect(() => {
    console.log(JSON.parse(eventChosen).event_type);
    setChosenEvent(JSON.parse(eventChosen).event_type);
  }, [eventChosen]);

  useEffect(() => {
    console.log(mapData);
    console.log(mapData.time_aggregation);
    setOptions({
      average: mapData.time_aggregation === "average",
      median: mapData.time_aggregation === "median",
      sum: mapData.time_aggregation === "sum",
      min: mapData.time_aggregation === "min",
      max: mapData.time_aggregation === "max",
    });
    setTimeAgg(mapData.time_aggregation);
  }, [mapData]);

  useEffect(() => {
    let dataEvent = localStorage.getItem("eventFilter");
    setEventData(JSON.parse(dataEvent));
    console.log(mapData.time_aggregation);
    console.log(anomalyOperation);
  }, []);
  console.log(mode);
  return (
    <div className="eventFilter">
      <div
        style={{
          position: "relative",
          width: "250px",
          borderRadius: "5px",
          // height: "125px",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          //   border: "solid 2px black",
          top: "32px",
          zIndex: "1000",
          left: "390px",
          padding: "10px",
          backgroundColor: "white",
        }}
      >
        {(!anamolyModal || !genralModal || !isVisible) && (
          <>
            <Checkbox style={{ display: "none" }}></Checkbox>
            <div
              className="label_container"
              style={{ marginTop: "0", paddingTop: "10px" }}
            >
              <span className="metric_label">GENERAL PERFORMANCE</span>
              <Checkbox
                className="metric_options"
                onChange={(e) => handleGeneral(e)}
                checked={eventNameChosen === "general_performance"}
              ></Checkbox>
              {eventNameChosen === "general_performance" && (
                <EditFilled
                  style={{
                    position: "relative",
                    top: "4px",
                    left: "25px",
                    color: "#1890FF",
                  }}
                  onClick={() => setGeneralVisible(true)}
                />
              )}
            </div>
            <div className="label_container">
              <span className="metric_label">ANOMALY ANALYSIS</span>
              <Checkbox
                className="metric_options"
                onChange={(e) => handleAnamoly(e)}
                checked={eventNameChosen === "anomaly_analysis"}
              ></Checkbox>
              {eventNameChosen === "anomaly_analysis" && (
                <EditFilled
                  style={{
                    position: "relative",
                    top: "4px",
                    left: "52px",
                    color: "#1890FF",
                  }}
                  onClick={() => setAnamolyVisible(true)}
                />
              )}
            </div>
            <div className="label_container">
              <span className="metric_label">COMPARE TIME PERIODS</span>
              <Checkbox
                className="metric_options"
                onChange={(e) => handleCompare(e)}
                checked={eventNameChosen === "compare_time_periods"}
              ></Checkbox>
              {eventNameChosen === "compare_time_periods" && (
                <EditFilled
                  style={{
                    position: "relative",
                    top: "4px",
                    left: "25px",
                    color: "#1890FF",
                  }}
                  onClick={() => setIsVisible(true)}
                />
              )}
            </div>
          </>
        )}
        <Divider style={{ margin: "5px 0 5px 0" }} />
        {/* <div
          style={{
            textAlign: "end",
            paddingRight: "20px",
            paddingBottom: "20px",
            paddingTop: "10px",
          }}
        >
          <button
            className="profeci_event_button"
            style={{ height: "30px", marginRight: "15px" }}
            onClick={() => closeFilter()}
          >
            Save
          </button>
          <button
            className="profeci_event_button"
            style={{ height: "30px" }}
            onClick={() => closeFilter()}
          >
            Cancel
          </button>
        </div> */}
        {isVisible && (
          <Modal
            title={<div style={{ textAlign: "left" }}> EDIT EVENT</div>}
            centered
            visible={isVisible}
            onOk={() => {
              if (
                (firstStart !== undefined ||
                  mapData.event_first_period_start !== undefined) &&
                (firstEnd || mapData.event_first_period_end !== undefined) &&
                (secondStart !== undefined ||
                  mapData.event_second_period_start !== undefined) &&
                (secondEnd !== undefined ||
                  mapData.event_second_period_end !== undefined) &&
                timeAgg !== undefined
              ) {
                postEventSelection("COMPARE_TIME_PERIOD");
                setIsVisible(false);
              } else {
                message.error("Please select operations and periods");
              }

              // comparePeriods(response);
            }}
            onCancel={() => setIsVisible(false)}
            maskClosable={true}
            width={800}
          >
            <div className="date_range_picker">
              <div className="range_periods">
                <p className="range_headers">Select two time periods</p>
                <div className="selection_dates">
                  <div className="first_period">
                    <p className="range_headers">
                      <span className="yellow_dot"></span>First Period
                    </p>
                    <RangePicker
                      className="range_pick_1"
                      onChange={onChange}
                      defaultPickerValue={
                        start !== null
                          ? [moment(start), moment(start)]
                          : [moment(), moment()]
                      }
                      defaultValue={
                        mapData.event_first_period_start !== "" ||
                        mapData.event_first_period_end !== ""
                          ? [
                              moment(mapData.event_first_period_start),
                              moment(mapData.event_first_period_end),
                            ]
                          : []
                      }
                    />
                  </div>
                  <div className="second_period">
                    <p className="range_headers">
                      <span className="purple_dot"></span>Second Period
                    </p>
                    <RangePicker
                      className="range_pick_2"
                      onChange={onChangeSecond}
                      defaultPickerValue={
                        start !== null
                          ? [moment(start), moment(start)]
                          : [moment(), moment()]
                      }
                      defaultValue={
                        mapData.event_second_period_start !== "" ||
                        mapData.event_second_period_end !== ""
                          ? [
                              moment(mapData.event_second_period_start),
                              moment(mapData.event_second_period_end),
                            ]
                          : []
                      }
                    />
                  </div>
                </div>
              </div>
              <Divider type="vertical" style={{ height: "227px" }} />
              <div className="operation">
                <p className="range_headers">Select an operation on time</p>
                <div className="range_options">
                  <div className="label_container">
                    <Average className="metric_icon" />
                    <span className="metric_label">Average</span>
                    <Checkbox
                      className="metric_options"
                      onChange={(e) => {
                        handleOperationSelection(e, "average");
                      }}
                      checked={checkboxGroup.average}
                    ></Checkbox>
                  </div>
                  <div className="label_container">
                    <Median className="metric_icon" />
                    <span className="metric_label">Median</span>
                    <Checkbox
                      className="metric_options"
                      onChange={(e) => {
                        handleOperationSelection(e, "median");
                      }}
                      checked={checkboxGroup.median}
                    ></Checkbox>
                  </div>
                  <div className="label_container">
                    <Sum className="metric_icon" />
                    <span className="metric_label">Sum</span>
                    <Checkbox
                      className="metric_options"
                      onChange={(e) => {
                        handleOperationSelection(e, "sum");
                      }}
                      checked={checkboxGroup.sum}
                    ></Checkbox>
                  </div>
                  <div className="label_container">
                    <Min className="metric_icon" />
                    <span className="metric_label">Min</span>
                    <Checkbox
                      className="metric_options"
                      onChange={(e) => {
                        handleOperationSelection(e, "min");
                      }}
                      checked={checkboxGroup.min}
                    ></Checkbox>
                  </div>
                  <div className="label_container">
                    <Max stroke="black" className="metric_icon" />
                    <span className="metric_label">Max</span>
                    <Checkbox
                      className="metric_options"
                      onChange={(e) => {
                        handleOperationSelection(e, "max");
                      }}
                      checked={checkboxGroup.max}
                    ></Checkbox>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}

        {genralModal && (
          <>
            <Modal
              title={<div style={{ textAlign: "left" }}> EDIT EVENT</div>}
              centered
              visible={genralModal}
              onOk={() => {
                if (
                  firstStart !== undefined &&
                  firstEnd !== undefined &&
                  timeAgg !== undefined
                ) {
                  postEventSelection("GENERAL_PERFORMANCE");
                  setGeneralVisible(false);
                } else {
                  message.error("Please select period and operation.");
                }
                // comparePeriods(response);
              }}
              onCancel={() => setGeneralVisible(false)}
              maskClosable={true}
              width={600}
            >
              <div className="general_edit">
                <div className="range_periods">
                  <p className="range_headers">
                    <span className="purple_dot"></span>Select Time Period
                  </p>
                  <RangePicker
                    defaultPickerValue={
                      start !== null
                        ? [moment(start), moment(start)]
                        : [moment(), moment()]
                    }
                    className="range_pick_1"
                    onChange={onChange}
                    defaultValue={
                      mapData.event_first_period_start !== "" ||
                      mapData.event_first_period_end !== "" ||
                      mapData !== {}
                        ? [
                            moment(mapData.event_first_period_start),
                            moment(mapData.event_first_period_end),
                          ]
                        : []
                    }
                  />
                </div>
                <Divider type="vertical" style={{ height: "227px" }} />

                <div className="general_select">
                  <div className="operation">
                    <p className="range_headers">Select an operation on time</p>
                    <div className="range_options">
                      <div className="label_container">
                        <Average className="metric_icon" />
                        <span className="metric_label">Average</span>
                        <Checkbox
                          className="metric_options"
                          onChange={(e) => {
                            handleOperationSelection(e, "average");
                          }}
                          checked={checkboxGroup.average}
                        ></Checkbox>
                      </div>
                      <div className="label_container">
                        <Median className="metric_icon" />
                        <span className="metric_label">Median</span>
                        <Checkbox
                          className="metric_options"
                          onChange={(e) => {
                            handleOperationSelection(e, "median");
                          }}
                          checked={checkboxGroup.median}
                        ></Checkbox>
                      </div>
                      <div className="label_container">
                        <Sum className="metric_icon" />
                        <span className="metric_label">Sum</span>
                        <Checkbox
                          className="metric_options"
                          onChange={(e) => {
                            handleOperationSelection(e, "sum");
                          }}
                          checked={checkboxGroup.sum}
                        ></Checkbox>
                      </div>
                      <div className="label_container">
                        <Min className="metric_icon" />
                        <span className="metric_label">Min</span>
                        <Checkbox
                          className="metric_options"
                          onChange={(e) => {
                            handleOperationSelection(e, "min");
                          }}
                          checked={checkboxGroup.min}
                        ></Checkbox>
                      </div>
                      <div className="label_container">
                        <Max className="metric_icon" />
                        <span className="metric_label">Max</span>
                        <Checkbox
                          className="metric_options"
                          onChange={(e) => {
                            handleOperationSelection(e, "max");
                          }}
                          checked={checkboxGroup.max}
                        ></Checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </>
        )}
        {anamolyModal && (
          <>
            <Modal
              title={<div style={{ textAlign: "left" }}> EDIT EVENT</div>}
              centered
              visible={anamolyModal}
              onOk={() => {
                if (
                  firstStart !== undefined &&
                  firstEnd !== undefined &&
                  first !== "" &&
                  second !== ""
                ) {
                  postEventSelection("ANOMALY");
                  setAnamolyVisible(false);
                } else {
                  console.log(first, second, firstStart, firstEnd, timeAgg);
                  message.error("Please select Anomaly operations and period ");
                }
                // comparePeriods(response);
              }}
              onCancel={() => setAnamolyVisible(false)}
              maskClosable={true}
              width={800}
            >
              <div className="operation_container">
                <div className="anamoly_date">
                  <RangePicker
                    className="range_pick_1"
                    onChange={onChange}
                    defaultPickerValue={
                      start !== null
                        ? [moment(start), moment(start)]
                        : [moment(), moment()]
                    }
                    defaultValue={
                      mapData.event_first_period_start !== "" ||
                      mapData.event_first_period_end !== "" ||
                      mapData !== {}
                        ? [
                            moment(mapData.event_first_period_start),
                            moment(mapData.event_first_period_end),
                          ]
                        : []
                    }
                  />
                </div>
                <div className="operations">
                  <p className="operation_heading">
                    Anomaly is captured by the{" "}
                  </p>
                  <p className="operation_heading">between the</p>
                  <Operations
                    parentCallback={(value) => {
                      setFirst(value);
                    }}
                    opChosen={mapData.time_aggregation_anomaly_one}
                  />
                </div>
                <div className="operations">
                  <p className="operation_heading">
                    <select
                      placeholder="Select"
                      className="operations_select"
                      onChange={(value) =>
                        setAnomalyOperation(value.target.value)
                      }
                      value={anomalyOperation}
                    >
                      <option value="difference">Difference</option>
                      <option value="ratio">Ratio</option>
                    </select>
                  </p>
                  <p
                    className="operation_heading"
                    style={{ position: "relative", top: "-2px" }}
                  >
                    and the
                  </p>
                  <Operations
                    style={{ paddingBottom: "10pX" }}
                    parentCallback={(value) => {
                      setSecond(value);
                    }}
                    opChosen={mapData.time_aggregation_anomaly_two}
                  />
                </div>
              </div>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};

export default eventFilter;
