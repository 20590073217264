export const colors = [
  "rgba(109, 91, 208, 0.8)",
  "rgba(235, 204, 44, 0.8)",
  "rgba(191, 157, 122, 0.8)",
  "rgba(2, 148, 165, 0.8)",
  "rgba(128, 173, 215, 0.8)",
  "rgba(153, 191, 170, 0.8)",
  "rgba(10, 189, 160, 0.8)",
  "rgba(129, 163, 167, 0.8)",

  "rgba(242, 47, 8, 0.8)",
];
