import React, { useEffect, useState, useRef } from "react";
import {
  Select,
  Button,
  Table,
  Tag,
  Input,
  Form,
  message,
  Spin,
  Tooltip,
  notification,
} from "antd";
import axios from "axios";
import FilterFilled from "@ant-design/icons";
import moment from "moment";
import dataSource from "./dummy";
import "./input.css";
import "../../App.css";
import FormItem from "antd/lib/form/FormItem";

const API_URL = process.env.REACT_APP_API_URL;

const inputTab = () => {
  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: type.toUpperCase(),
      description: description,
    });
  };
  const { Option } = Select;
  const propsFace = {
    mode: "multiple",
    maxTagCount: "responsive",
    showSearch: true,
    showArrow: true,
    allowClear: true,
    placeholder: "Checking",
    dropdownStyle: { zIndex: 2000 },
    // filterOption: (input = "", option = "") =>
    //   option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0,
  };
  const [pagination, setPagination] = useState({
    pageSize: 15,
  });
  // STATE VARIABLES
  const [unitSelected, setUnit] = useState([]);
  const [metricSelected, setMetric] = useState([]);
  const [timeSelected, setTime] = useState([]);
  const [actionselected, setAction] = useState([]);
  const [covariatselected, setCovariat] = useState([]);
  const [disableFlag, setDisableFlag] = useState(true);
  const { Search } = Input;
  const formRef = useRef();
  const [labelSubmit, setLabelSubmit] = useState(false);
  const [columnInfo, setColumnConfig] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableLabel, setTableLabel] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [timeOptions, setTimeOptions] = useState([]);
  const [metricOptions, setMetricOptions] = useState([]);
  const [actionOptions, setActionOptions] = useState([]);
  const [covarianceOptions, setCovarianceOptions] = useState([]);
  const [column, setColumnInfo] = useState({});
  const [deselectOverlap, setDeselectOverlap] = useState();

  const [checkValue, setCheckValue] = useState([]);

  const tagRender = (props) => {
    const { label, closable, onClose } = props;

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color={"rgba(111,82,237,80%)"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
          marginBottom: "2px",
          marginTop: "2px",
        }}
      >
        {label}
      </Tag>
    );
  };

  const arrayDifference = (arr1, arr2, allowedOptions) => {
    let tempArray = [];
    let ArrayOne = [];
    let ArrayTwo = [];
    if (arr1.length > arr2.length) {
      ArrayOne = [...arr1];
      ArrayTwo = [...arr2];
    } else {
      ArrayOne = [...arr2];
      ArrayTwo = [...arr1];
    }

    ArrayOne.map((x) => {
      let flag = true;
      ArrayTwo.map((y) => {
        if (x.value === y.value) {
          flag = false;
        }
      });

      if (flag && includeCheck(arr1, x.value)) {
        tempArray.push(x);
      }
    });

    ArrayTwo.map((x) => {
      let flag = true;
      ArrayOne.map((y) => {
        if (x.value === y.value) {
          flag = false;
        }
      });
      if (flag && includeCheck(arr1, x.value)) {
        tempArray.push(x);
      }
    });
    return tempArray;
  };

  const onSearch = (value) => console.log(value);
  const [metSelection, setMet] = useState([]);
  const [covSelection, setCov] = useState([]);
  const [ActSelection, setAct] = useState([]);
  const [dropLoader, setDropLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setDropLoader(false);
    }, 1500);
  }, [dropLoader]);

  // Mainting states for payload
  useEffect(() => {
    let flag = true;
    console.log(metSelection, covSelection, ActSelection);
    console.log("ALLOWED", allowedOptions);
    console.log("ALL SELECTED", allSelected);
    console.log(
      "Total field selections:",
      metSelection,
      unitSelected,
      ActSelection,
      timeSelected
    );
    if (unitSelected.length === 0 || timeSelected.length === 0) {
      flag = false;
    }
    if (ActSelection.length === 0 || metSelection.length === 0) {
      flag = false;
    }
    if (flag) {
      setDisableFlag(false);
    } else {
      setDisableFlag(true);
    }
    console.log("Disable button", flag);
  }, [metSelection, covSelection, unitSelected, ActSelection, timeSelected]);

  const setMetricSelection = (record) => {
    let metArray = [...metSelection];
    metArray.push(record);
    setMet(metArray);
  };
  const setCoSelection = (record) => {
    let metArray = [...covSelection];
    metArray.push(record);
    setCov(metArray);
  };

  const setAcSelections = (record) => {
    let acArray = [...ActSelection];
    acArray.push(record);
    setAct(acArray);
  };
  const updateCovariate = (value) => {
    console.log(value, "UPDATE COVARIATE");
    let originalCovariate = [];
    covSelection.map((x) => {
      if (x.value !== value) {
        originalCovariate.push(x);
      }
    });
    setCov(originalCovariate);
    handleDeselectOverlap(value);
  };
  const updateMetrics = (value) => {
    console.log(value, "UPDATE METRICS");
    let originalMetrics = [];
    metSelection.map((x) => {
      if (x.value !== value) {
        originalMetrics.push(x);
      }
    });
    setMet(originalMetrics);
    handleDeselectOverlap(value);
  };

  const updateAct = (value) => {
    console.log(value, "UPDATE METRICS");
    let originalAct = [];
    ActSelection.map((x) => {
      if (x.value !== value) {
        originalAct.push(x);
      }
    });
    setAct(originalAct);
  };

  const [allSelected, setAll] = useState([]);
  const [allowedOptions, setAllowed] = useState([]);

  // Comparision of object
  const includeCheck = (arrayTo, value) => {
    let flag = false;
    arrayTo.map((x) => {
      if (x.value === value) {
        flag = true;
      }
    });
    return flag;
  };

  const handleSingleSelection = (record, pop) => {
    console.log(record, pop);
    let tempAll = [...allSelected];
    let availableOptions = [];
    console.log(record, pop, "HANDLING SELECTIONS");

    if (pop.length === 0 && !includeCheck(allSelected, record.value)) {
      tempAll.push(record);
      console.log("TempAll Selection Array", tempAll);
      setAll(tempAll);
    }
    if (
      tempAll.length !== 0 &&
      record !== undefined &&
      !includeCheck(allSelected, record.value)
    ) {
      if (!Array.isArray(pop)) {
        tempAll.push(record);
        let filteredArray = tempAll.map((x) => {
          return x.value !== pop.value ? x : 0;
        });
        console.log(tempAll);
        tempAll = filteredArray.filter(function(val) {
          return val !== 0;
        });
        console.log(tempAll);
        setAll(tempAll);
        console.log("TempAll Selection Array", tempAll);
      }
      if (Array.isArray(pop) && pop.length > 0) {
        tempAll.push(record);
        tempAll = arrayDifference(tempAll, pop, false);
        setAll(tempAll);
        console.log("TempAll Selection Array", tempAll);
      }
      availableOptions = arrayDifference(checkValue, tempAll, false);
      console.log("Available Options", availableOptions);
      setAllowed(availableOptions);
    }
    if (record === undefined) {
      let availableOptions = [...allowedOptions];
      let filteredArray = tempAll.map((x) => {
        return x.value !== pop ? x : 0;
      });
      console.log(tempAll);
      tempAll = filteredArray.filter(function(val) {
        return val !== 0;
      });
      console.log(tempAll);
      setAll(tempAll);
      availableOptions = arrayDifference(checkValue, tempAll, false);
      console.log("Available Options", availableOptions);
      setAllowed(availableOptions);
    }
  };

  // Updation of available and all selected
  const handleSelection = (record, popSelection) => {
    let tempAll = [...allSelected];
    let availableOptions = [];
    console.log(record, popSelection, "HANDLING SELECTIONS");
    tempAll.push(record);
    setAll(tempAll);

    availableOptions = arrayDifference(checkValue, tempAll, false);
    console.log("Available Options", availableOptions);
    setAllowed(availableOptions);
  };

  // Deslection
  const handleDeselect = (value) => {
    console.log(value);
    let appendFlag = true;
    let tempArray = [...allowedOptions];
    let all = [];
    let pushRecord = "check";
    checkValue.map((x) => {
      if (x.value === value) {
        tempArray.push(x);
        pushRecord = x;
      }
    });
    allSelected.map((z) => {
      if (z.value !== value) {
        all.push(z);
      }
    });
    setAll(all);
    setAllowed(tempArray);
  };

  // To make sure the deslection is done from both input fields to make it available again
  const handleDeselectOverlap = (record) => {
    console.log(
      record,
      "HANDLE DESELECT",
      metSelection,
      covSelection,
      allowedOptions
    );
    if (
      !includeCheck(allowedOptions, record) &&
      (!includeCheck(covSelection, record) ||
        !includeCheck(metSelection, record))
    ) {
      handleDeselect(record);
    }
  };

  const updatedOptionArray = (array1, array2) => {
    let array3 = array1.concat(array2);
    const uniqueValues = new Set(array3.map((v) => v));
    console.log(Array.from(uniqueValues));
    return Array.from(uniqueValues);
  };

  const getCovOverlap = (selection) => {
    console.log(covSelection);
    let tempArray = [];
    covSelection.map((obj) => {
      console.log(includeCheck(metricOptions, obj.value));
      includeCheck(metricOptions, obj.value)
        ? tempArray.push(obj)
        : console.log("Dropped Objects", obj);
    });
    console.log("TempArray", tempArray);
    return tempArray;
  };

  const getDropdownValues = (objectArray) => {
    let tempArray = objectArray.map((obj) => {
      obj["value"] = obj["key"];
      delete obj["key"];
      delete obj["available"];
      return obj;
    });
    console.log(tempArray);
    return tempArray;
  };

  const getCustomisedElement = (label, type, text) => {
    console.log(label, type, text);
    if (text !== undefined) {
      switch (type) {
        case "datetime":
          return (
            <span
              style={{
                backgroundColor: "rgba(111,82,237,0.1)",
                padding: "4px",
                borderRadius: "5px",
                position: "relative",
                left: "-4px",
              }}
            >
              {moment(text).format("ddd, MMM Do YYYY")}
            </span>
          );
        case "float":
          return <span>{text.toFixed(2)}</span>;
        case "int":
          return <span>{text}</span>;
        case "bool":
          return text ? (
            <span
              style={{
                backgroundColor: "#E1FCEF",
                padding: "5px",
                borderRadius: "3px",
              }}
            >
              {text ? "Yes" : "No"}
            </span>
          ) : (
            <span style={{ backgroundColor: "#FAF0F3", padding: "5px" }}>
              {text.toString()}
            </span>
          );
        default:
          return <span>{text}</span>;
      }
    }
  };

  // ================================ SIDE EFFECTS ==============================

  const submitProjectLabels = async () => {
    setLabelSubmit(true);
    try {
      let metricsPayload = [];
      let covariatesPayload = [];
      let actionPayload = [];
      console.log(
        "Payload Creation",
        unitSelected,
        timeSelected,
        ActSelection,
        metSelection,
        covSelection
      );

      ActSelection.map((obj) => {
        let tempObj = {
          action_name: obj.value,
          action_column: obj.label,
          composite: false,
          composite_query: {},
        };
        actionPayload.push(tempObj);
      });

      covSelection.map((obj) => {
        let tempObj = {
          covariate_name: obj.value,
          covariate_column: obj.label,
          composite: false,
          composite_query: {},
        };
        covariatesPayload.push(tempObj);
      });

      metSelection.map((obj) => {
        let tempObj = {
          metric_name: obj.value,
          metric_column: obj.label,
          composite: false,
          composite_query: {},
        };
        metricsPayload.push(tempObj);
      });

      const data = JSON.stringify({
        table_name: "tumor_size",
        project_id: 0,
        unit_column: unitSelected.label,
        time_column: timeSelected.label,
        metrics: metricsPayload,
        covariates: covariatesPayload,
        actions: actionPayload,
      });
      const submitConfig = {
        method: "post",
        url: `${API_URL}/submit_project_labels`,
        headers: {
          key: "Y6KBUDa97drMc6r5oDlG4aHSoVYulRJb",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: data,
      };

      axios(submitConfig)
        .then(function(response) {
          console.log(JSON.stringify(response.data));
          // message.success("Successfully Submitted Project Labels");
          openNotificationWithIcon(
            "success",
            "Successfully Submitted Project Labels"
          );
          setLabelSubmit(false);
          document.getElementById("label_form").reset();
        })
        .catch(function(error) {
          console.log(error);
          openNotificationWithIcon("error", "Failed to submit project labels");
          // message.error("Failed to submit project labels");
        });
    } catch (e) {
      message.error("Failed to post labels");
      console.log("err...", e);
    }
  };

  // Get table Info

  const getTableInfoData = async (name) => {
    try {
      let tableConfig = {
        method: "get",
        url: `${API_URL}/table_view/?table_name=${name}`,
        headers: {
          key: "Y6KBUDa97drMc6r5oDlG4aHSoVYulRJb",
        },
      };

      axios(tableConfig)
        .then(function(response) {
          // message.success("Successfully fetched table information and data");
          openNotificationWithIcon(
            "success",
            "Successfully fetched table information and data"
          );
          console.log(response.data);
          setColumnConfig(response.data.columns);
          setTableData(response.data.data);
        })
        .catch(function(error) {
          console.log(error);
          message.error(
            `${error.code} ${error.name}, ${error.message} "Failed to fetch Table information and data"`
          );
        });
    } catch (e) {
      console.log("err...", e);
    }
  };

  const getColumnInfo = async (name) => {
    const config = {
      method: "get",
      url: `${API_URL}/label_options/?table_name=${name}`,
      headers: {
        key: "Y6KBUDa97drMc6r5oDlG4aHSoVYulRJb",
      },
    };
    try {
      axios(config)
        .then(function(response) {
          console.log(response.data);
          setColumnInfo(response.data);
          openNotificationWithIcon(
            "success",
            "Fetched dropdown data successfully"
          );
          // message.success("Fetched dropdown data successfully");
        })
        .catch(function(error) {
          message.error(`${error.code} ${error.name}, ${error.message}`);
          console.log(error);
        });
    } catch (e) {
      message.error("Failed to fetch the dropdowns");
      console.log("err...", e);
    }
  };

  useEffect(() => {
    if (Object.keys(column).length !== 0) {
      console.log("COLUMN INFO CHECK", column, column["unit columns"]);
      let unitCheck = getDropdownValues(column["unit columns"]);
      setUnitOptions(unitCheck);
      setCheckValue(unitCheck);
      setActionOptions(getDropdownValues(column["action columns"]));
      setCovarianceOptions(getDropdownValues(column["covariates columns"]));
      setMetricOptions(getDropdownValues(column["metric columns"]));
      setTimeOptions(getDropdownValues(column["time columns"]));
    }
  }, [column]);

  useEffect(() => {
    getColumnInfo("tumor_size");
    getTableInfoData("tumor_size");
    console.log("............................................", column);
  }, []);

  const [overlap, setOverlap] = useState([]);

  useEffect(() => {
    console.log(unitOptions);
  }, [unitOptions]);

  useEffect(() => {
    console.log("Changed", overlap);
  }, [metricSelected, covariatselected, overlap]);

  useEffect(() => {
    let columnConfig = [];
    console.log(columnInfo);
    columnInfo.map((obj) => {
      let tempObject = {
        title: (
          <Tooltip title={obj.label.toUpperCase()}>
            <span>
              {obj.label.toUpperCase().length > 13
                ? obj.label
                    .toUpperCase()
                    .substring(0, 11)
                    .concat("...")
                : obj.label.toUpperCase()}
            </span>
          </Tooltip>
        ),
        dataIndex: obj.value,
        key: obj.value,
        width: obj.type === "datetime" ? 200 : 150,
        render: (text) => {
          console.log(text, "TEXTING");
          return getCustomisedElement(obj.label, obj.type, text);
        },
      };
      columnConfig.push(tempObject);
    });
    console.log(columnConfig);
    setTableLabel(columnConfig);
  }, [tableData, columnInfo]);

  return (
    <div className="input_tab">
      <div className="labels">
        <div className="create_labels">
          <h2
            style={{
              fontWeight: "640",
              textAlign: "center",
              padding: "30px",
              backgroundColor: "#F4F7FC",
              margin: 0,
              borderRadius: "7px",
              fontSize: "22px",
              letterSpacing: "1.1px",
            }}
          >
            CREATE LABELS
          </h2>
          <div className="label_form">
            <Spin spinning={labelSubmit}>
              <Form ref={formRef} id="label_form">
                <h4 style={{ margin: 0, paddingBottom: "10px" }}>
                  Unit column
                </h4>
                <div
                  style={{
                    width: "100%",
                    marginBottom: "20px",
                    height: "fit-content",
                  }}
                >
                  <FormItem name="unit">
                    <Select
                      className="single_select"
                      name="Unit"
                      style={{ width: "100%", height: "40px" }}
                      options={arrayDifference(unitOptions, allSelected)}
                      tagRender={tagRender}
                      showArrow
                      placeholder="Select Unit Column"
                      onChange={(value, record) => {
                        // setSelected(record);
                        handleSingleSelection(record, unitSelected);
                        setUnit(record === undefined ? [] : record);
                      }}
                      onDeselect={(value) => {
                        handleDeselect(value);
                      }}
                      allowClear
                      showSearch
                      loading={dropLoader}
                    ></Select>
                  </FormItem>
                </div>

                <h4 style={{ margin: 0, paddingBottom: "10px" }}>
                  Time column
                </h4>
                <div style={{ width: "100%", marginBottom: "20px" }}>
                  <FormItem name="time">
                    <Select
                      className="single_select"
                      style={{ width: "100%", height: "40px" }}
                      tagRender={tagRender}
                      showArrow
                      placeholder="Select Time Column"
                      options={arrayDifference(timeOptions, allSelected)}
                      onChange={(value, record) => {
                        handleSingleSelection(record, timeSelected);
                        setTime(record === undefined ? [] : record);
                      }}
                      onDeselect={(value) => {
                        handleDeselect(value);
                      }}
                      allowClear
                      showSearch
                      loading={dropLoader}
                    ></Select>
                  </FormItem>
                </div>

                <h4 style={{ margin: 0, paddingBottom: "10px" }}>
                  Metric column
                </h4>
                <div
                  style={{
                    width: "100%",
                    marginBottom: "20px",
                    height: "fit-content",
                  }}
                >
                  <FormItem name="metric">
                    <Select
                      mode="multiple"
                      style={{ width: "100%", height: "40px" }}
                      tagRender={tagRender}
                      showArrow
                      placeholder="Select Metric Column"
                      options={arrayDifference(
                        metricOptions,
                        allSelected
                      ).concat(getCovOverlap())}
                      onSelect={(value, record) => {
                        handleSelection(record, metricSelected);
                        setMetricSelection(record);
                        setMetric(record);
                      }}
                      onDeselect={(value) => {
                        updateMetrics(value);
                      }}
                      loading={dropLoader}
                    ></Select>
                  </FormItem>
                </div>

                <h4 style={{ margin: 0, paddingBottom: "10px" }}>
                  Action column
                </h4>
                <div style={{ width: "100%", marginBottom: "20px" }}>
                  <Form.Item name="action">
                    <Select
                      mode="multiple"
                      style={{ width: "100%", height: "40px" }}
                      tagRender={tagRender}
                      showArrow
                      placeholder="Select Action Column"
                      options={arrayDifference(actionOptions, allSelected)}
                      onSelect={(value, record) => {
                        handleSelection(record, actionselected);
                        setAction(record);
                        setAcSelections(record);
                      }}
                      onDeselect={(value) => {
                        handleDeselect(value);
                        updateAct(value);
                      }}
                      loading={dropLoader}
                    ></Select>
                  </Form.Item>
                </div>
                <h4 style={{ margin: 0, paddingBottom: "10px" }}>
                  Covariate Column
                </h4>
                <div style={{ width: "100%", marginBottom: "0px" }}>
                  <Form.Item name="COVARIAT">
                    <Select
                      mode="multiple"
                      style={{ width: "100%", height: "40px" }}
                      tagRender={tagRender}
                      showArrow
                      placeholder="Select Action Column"
                      options={arrayDifference(
                        covarianceOptions,
                        allSelected
                      ).concat(metSelection)}
                      onSelect={(value, record) => {
                        handleSelection(record, covariatselected);
                        setCoSelection(record);
                        setCovariat(record);
                      }}
                      onDeselect={(value) => {
                        updateCovariate(value);
                      }}
                      loading={dropLoader}
                    ></Select>
                  </Form.Item>
                </div>
                <div style={{ position: "relative", top: "-10px" }}>
                  <button
                    disabled={disableFlag}
                    className="profeci_event_button"
                    onClick={() => submitProjectLabels()}
                    style={{
                      height: "30px",
                      float: "right",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    CREATE
                  </button>
                </div>
              </Form>
            </Spin>
          </div>
        </div>
      </div>

      {/* Table Data  */}
      <div className="input_table">
        {/* <div className="input_table_filter">
          {" "}
          <Button
            onClick={() => {
              getColumnInfo("store_sales");
              getTableInfoData("store_sales");
            }}
          >
            Store Sales
          </Button>
          <Button
            onClick={() => {
              getColumnInfo("tumor_size");
              getTableInfoData("tumor_size");
            }}
          >
            Tumor Size
          </Button>
        </div> */}
        <div className="table_loaded">
          <Spin spinning={columnInfo.length === 0} delay={500}>
            <Table
              dataSource={tableData}
              columns={tableLabel}
              scroll={{ y: "750px" }}
              pagination={pagination}
            />
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default inputTab;
